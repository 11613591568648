import React from 'react';
import moment from 'moment';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import numberField from 'components/hoc/numberField';
import decimalField from 'components/hoc/decimalField';
import currencyField from 'components/hoc/currencyField';
import FormControlWraper from 'components/base/FormControlWraper';
import InputDate from 'components/base/InputDate';
import Dropdown, { CheckboxDropdown } from 'components/base/Dropdown';
import classNames from 'classnames';

import css from './style.scss';

const NumberInput = numberField('input');
const DecimalInput = decimalField('input');
const CurrencyInput = currencyField('input');

const Field = ({
  name,
  placeholder,
  dropdownClassName,
  searchable,
  search,
  onChange,
  onBlur,
  onInputRef,
  direction,
  options,
  field,
  maxLength,
  onChangeValue
}) => {
  const { type, futureDateEnabled } = field;

  let min = null;
  let max = null;

  const dropdown = ( type === 'text' && !!(options || field.options) ) || type==='dropdown';
  const text = !dropdown && (type === 'text' || type === 'percent');
  const props = { onChange, onBlur, name, maxLength, placeholder, value: search.get(name, ''), [text || type === 'textarea' ? 'ref' : 'onInputRef']: onInputRef };
  const today = moment().startOf('day');

  if (type === 'percent') {
    const val = String(props.value);
    if (val === '' || val == null) props.value = '';
    else if (!isNaN(val)) {
      props.value = `${Math.round(val * 100000) / 1000}${val === '00' || (val.includes('.') && val.endsWith('0')) ? '.' : ''}`;
    }
  } else if (type === 'date') {
    const base = name.substring(0, name.length - 3);
    props.dateFormat = 'MM/dd/yy';
    if (typeof props.value === 'number') props.value = moment(props.value);
    if (name.endsWith('Min')) {
      min = props.value;
      max = search.get(`${base}Max`);
    } else {
      min = search.get(`${base}Min`);
      max = props.value;
    }
  }

  if (options || field.options) props.options = options || field.options;
  if (type === 'number' && name && name.toLowerCase().includes('year')) props.maxLength = 4;

  return (
    <div className={classNames(css.inputContainer, { [css.extraWide]: type === 'list' || type === 'text', [css.fullWidth]: type === 'textarea' })}>
      <FormControlWraper>
        {type !== 'number' ? null : <NumberInput {...props} type="text" />}
        {type !== 'decimal' ? null : <DecimalInput {...props} type="text" />}
        {!text ? null : <input {...props} type="text" />}
        {type !== 'textarea' ? null : <textarea {...props} rows={3}>{props.value}</textarea>}
        {type !== 'currency' ? null : <CurrencyInput {...props} type="text" />}
        {type !== 'list' ? null : <CheckboxDropdown {...props} direction={direction} dropdownClassName={dropdownClassName} searchable={searchable} skipCatcherCheck />}
        {!dropdown ? null : <Dropdown {...props} onChangeValue={onChangeValue} />}
        {type !== 'date' ? null : <InputDate
          {...props}
          startDate={typeof min === 'number' ? moment(min) : min}
          endDate={typeof max === 'number' ? moment(max) : max}
          value={props.value === '' ? null : props.value}
          selectsStart={name.endsWith('Min')}
          selectsEnd={name.endsWith('Max')}
          isClearable
          showYearDropdown
          openToDate={props.value === '' ? today : props.value}
          maxDate={futureDateEnabled ? null : today}
        />}
      </FormControlWraper>
      {!maxLength ? null : <div className={css.subText}>{pluralize('Character', Math.max(0, maxLength - props.value.length), true)} Remaining</div> }
    </div>
  );
};

Field.propTypes = {
  type: PropTypes.string,
};

Field.defaultProps = {
  type: 'number',
};

export default Field;
