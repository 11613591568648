/** @flow */
import React, { PureComponent } from 'react';

import SVG from 'components/base/SVG';
import classNames from 'classnames';
import css from './style.scss';
import dropdownable from './decorate';
import PropTypes from 'prop-types';
import { event } from 'react-ga';

// type TOption = string | { label: string, value: * }

function getOptions(options, placeholder) {
  if (!options || options == undefined) return null;
  let opts = options.map((option) => {
    if (typeof option !== 'string') {
      const { value, label = value } = option;
      const labelStr = label ? String(label) : ''; // Ensure label is a string
      if (labelStr.substring(0, labelStr.indexOf(' ')) === 'AUTOMATION_DONT_DELETE')
        return <option id={'AUTOMATION_DONT_DELETE'} key={`${label}${value}`} value={value}>{label}</option>;
      else 
        return <option key={`${label}${value}`} value={value}>{label}</option>;
    }
    return <option key={option} value={option}>{option}</option>;
  });

  if (opts.length > 1 && placeholder) opts.unshift(<option key={placeholder} value={''}>{placeholder}</option>);
  return opts;
}

class Dropdown extends PureComponent {
  render() {
    const { className, disabled, name, onChange, groups, options, placeholder, comboboxClassName, input, onInputRef, onChangeValue, ...rest } = this.props;

    const opts = groups ?
      groups.map((group) => {
        if (!group.options.length) return null;
        if (!group.label) return getOptions(group.options, placeholder);

        return (
          <optgroup key={group.label} label={group.label}>
            {getOptions(group.options, placeholder)}
          </optgroup>
        );
      }) :
      getOptions(options, placeholder);

    let value = 'value' in this.props ? { value: this.props.value == null ? '' : this.props.value } : {};
    //if (comboboxClassName) value = {}



    // NOTE: input attribute is needed for redux form, used in a couple places.
    return (
      <div className={classNames(className, css.dropdown)}>
        <SVG icon="iconCaretDown" className={css.iconCaretDown} />
        <select
          {...rest}
          name={name}
          ref={onInputRef}
          className={classNames(comboboxClassName, css.control)}
          onChange={(event)=> {
            const selectedValue = event.target.value;
            onChange(event)
            if(onChangeValue)
              onChangeValue(selectedValue)
            }}
          
          disabled={disabled}
          {...value}
          {...input}
        >
          {opts}
        </select>
      </div>
    );
  }
}

Dropdown.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.instanceOf(Array),
};

Dropdown.defaultProps = {
  onChange: () => { },
  input: () => { },
};

export { dropdownable };
export default Dropdown;