import Button from "components/base/Button";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import CloseWhite from "../../../../../assets/images/close-icon-white.svg";
import { removeFilterById, removeLocalFilter, selectLocalFilter, showAllButtonEditOption } from "../../../../../data/search";
import css from "../.././style.scss";
import newsearchfilter from "../SearchFilterNew.scss";
import Auction from "../../../../../assets/images/auction.svg";
import BankOwned from "../../../../../assets/images/bank-owned.svg";
import Bankruptcy from "../../../../../assets/images/bankruptcy.svg";
import CashBuyers from "../../../../../assets/images/cash-buyer.svg";
import Divorce from "../../../../../assets/images/divorce.svg";
import FailedListings from "../../../../../assets/images/failedListings.svg";
import Flippers from "../../../../../assets/images/flippers.svg";
import FreeClear from "../../../../../assets/images/free-and-clear.svg";
import HighEquity from "../../../../../assets/images/high-equity.svg";
import Liens from "../../../../../assets/images/liens.svg";
import OnMarket from "../../../../../assets/images/mls.svg";
import PreForeclosures from "../../../../../assets/images/pre-foreclosures.svg";
import PreProbate from "../../../../../assets/images/pre-probate.svg";
import SeniorOwners from "../../../../../assets/images/senior-owners.svg";
import TaxDelinquency from "../../../../../assets/images/tax-delinquency.svg";
import TiredLandlords from "../../../../../assets/images/tired-landlords.svg";
import UpsideLand from "../../../../../assets/images/upside-down.svg";
import VacantLand from "../../../../../assets/images/vacant-land.svg";
import Vacant from "../../../../../assets/images/vacant.svg";
import ZombieProperties from "../../../../../assets/images/zombie-properties.svg";
import classNames from "classnames";
import { v4 as uuidv4 } from 'uuid';


const ShowAllButton = ({ sectionKey }) => {

  const getLocalFilter = useSelector(selectLocalFilter);
  const dispatch = useDispatch();

  const [isOpen, setOpen] = useState(true);
  const [list, setList] = useState(null);
  const showAllDropdownRef = useRef(null);


  useEffect(() => {
    setList(groupByKey(getLocalFilter, "key"));
    // console.log(list)
  }, [getLocalFilter]);

  const setOpenPopup = () => {
    setOpen(!isOpen);
  };


  const editAction = (data) => {
    data.focuseID = Math.random()
   dispatch(showAllButtonEditOption(data))
  //  setOpenPopup()
  }
  


  const groupByKey = (array, key) => {
    return array.reduce((acc, obj) => {
      const groupKey = obj[key];
      acc[groupKey] = acc[groupKey] || [];
      acc[groupKey].push(obj);
      return acc;
    }, {});
  }

  const getDecimalPlaces = (value) => {
    if (!Number.isFinite(value)) return 0; // Handle non-numeric cases
    let str = value.toString();
    if (str.includes('.')) {
        return str.split('.')[1].length;
    }
    return 0;
  }
  
  const roundToDynamic = (value) => {
    let decimals = getDecimalPlaces(String(value));
    let factor = Math.pow(10, decimals);
    return Math.round(String(value) * factor) / factor;
  }

  const formatDisplayValue = (showALLDisplayValue) => {
    if (!showALLDisplayValue) return '';
    
    if (showALLDisplayValue.includes('to')) {
      // Handle range: "0.4 to 0.5"
      const [min, max] = showALLDisplayValue.split('to').map((val) => val.trim());
      return `${roundToDynamic(Number(min) * 100)} to ${roundToDynamic(Number(max) * 100)}`;
    } else if (showALLDisplayValue.includes('or more')) {
      // Handle "0.4 or more"
      const value = showALLDisplayValue.replace('or more', '').trim();
      return `${roundToDynamic(Number(value) * 100)} or more`;
    } else if (showALLDisplayValue.includes('or fewer')) {
      // Handle "0.6 or fewer"
      const value = showALLDisplayValue.replace('or fewer', '').trim();
      return `${roundToDynamic(Number(value) * 100)} or fewer`;
    }
    // Default case: Return the input if no known pattern is matched
    return showALLDisplayValue;
  };


  const getDisplayValue = (item) => {
    const keysRequiringPercentage = [
      'estimatedValueGrowth',
      'grossYield',
      'estimatedEquityPercent',
      'loanToValueRatio',
      'itvRatio',
      'mortgage1LtvRatio',
      'mortgageInterestRate'
    ];
  
    if (keysRequiringPercentage.includes(item.apiKey)) {
     
      return formatDisplayValue(item.showALLDisplayValue)
    }
    
    return (item.showALLDisplayValue).replaceAll(",",", ");
  };

  const renderGroupItems = (groupItems) => {
   
    return (
      <ul className={newsearchfilter.clsFilterModuleBox}>
        {groupItems && groupItems.length && groupItems.map((item, index) => (
          // <>
            <li key={index} className={newsearchfilter.clsFilterModuleBoxList} >
              <div className={newsearchfilter.clsFilterModuleBoxHeader}>
                <h2>{item.id}</h2>
                <div className={newsearchfilter.clsBtnGrp} >
                  <Button  id={'newuifilterRef'+ uuidv4()} kind="link-default" className={newsearchfilter.clsEditBtn} size="large"
                    onClick={(ev) => {
                      ev.stopPropagation();
                      editAction(item);
                    }}
                  >  Edit </Button>
                  <Button id={'newuifilterRef'+ uuidv4()} kind="link-default" className={newsearchfilter.clsCloseBtn} size="large"
                    onClick={(ev) => {
                      ev.stopPropagation();
                      if (item?.id === 'Pre-Foreclosure') {
                        handleOldRemoveFilter("foreclosureStatus2");
                        handleClearFilter("Pre-Foreclosure Types");
                        handleOldRemoveFilter('preforeclosureIncluded')
                      }
                      handleClearFilter(item?.id);
                      handleOldRemoveFilter(item.removeID);
                    }}>
                    <ReactSVG
                      src={CloseWhite}
                      id={'newuifilterRef'+ uuidv4()}
                      className={newsearchfilter.svgCloseIcon}
                    />
                  </Button>
                </div>
              </div>
              <p className={newsearchfilter.clsFilterModulePara}>{item.showALLDisplayValue ?  getDisplayValue(item) : item.name}</p>
              
              {/* <Button kind="link-default" className={newsearchfilter.clsSavedBtn} size="large">  From Saved </Button> */}
            </li>
          // </>
        ))}
      </ul>
    )
  }

  const renderleadList = (groupItems) => {
    if (!groupItems || groupItems.length === 0) {
      return null; // Don't render anything if groupItems is null or empty
    }
    let leadList = groupItems && groupItems.length ? groupItems[0] : null
    return (
      <div key={leadList}>
        {/* <h2 className={newsearchfilter.clsHeading}>Lead Lists</h2> */}
        <div className={newsearchfilter.clsModuleBox}>
          <div className={newsearchfilter.clsModuleIcon}>
            <ReactSVG
              src={getSearchPanelIcons(leadList.name)}
              className={newsearchfilter.svgIcon}
            />
          </div>
          <p className={newsearchfilter.clsModuleName}>{leadList.name}</p>
          <Button kind="link-default" className={newsearchfilter.clsCloseBtn} size="large">
            <ReactSVG
              src={CloseWhite}
              className={newsearchfilter.svgCloseIcon}
              id={'newuifilterRef'+ uuidv4()}
              onClick={(ev) => {
                ev.stopPropagation();
                handleClearFilter(leadList?.id);
                handleOldRemoveFilter(leadList.removeID);
              }}
            />
          </Button>
        </div>
      </div>
    )
  }

  const getTittleById = (key) => {
    let tittle = ''

    switch (key) {
      case "ll":
        return 'Lead lists';
      case "pb":
        return 'Property Details';
      case "mls":
        return 'MLS';
      case "pfbo":
        return 'Pre-Foreclosure & Banked Owned';
      case "oio":
        return 'Owner Information & Occupancy';
      case "lbd":
        return 'Lien, Bankruptcy, & Divorce';
      case "ve":
        return 'Value & Equity';
      case "m":
        return 'Mortgage';
      default:
        return Auction;
    }

    return tittle
  }

  const getSearchPanelIcons = label => {
    switch (label) {
      case "Auctions":
        return Auction;
      case "Bank Owned":
        return BankOwned;
      case "Pre-Foreclosures":
        return PreForeclosures;
      case "Bankruptcy":
        return Bankruptcy;
      case "Cash Buyers":
        return CashBuyers;
      case "Divorce":
        return Divorce;
      case "Failed Listings":
        return FailedListings;
      case "Flippers":
        return Flippers;
      case "Free & Clear":
        return FreeClear;
      case "High Equity":
        return HighEquity;
      case "Liens":
        return Liens;
      case "On Market":
        return OnMarket;
      case "Senior Owners":
        return SeniorOwners;
      case "Pre-Foreclosures":
        return PreForeclosures;
      case "Pre-Probate (Deceased Owners)":
        return PreProbate;
      case "Tax Delinquencies":
        return TaxDelinquency;
      case "Tired Landlords":
        return TiredLandlords;
      case "Upside Down":
        return UpsideLand;
      case "Vacant":
        return Vacant;
      case "Vacant Land":
        return VacantLand;
      case "Zombie Properties":
        return ZombieProperties;
      case "Loading":
        return "";
      default:
        return Auction;
      // return House;
    }
  };

  // follow the order
  const orderedKeys = ["ll", "pb", "mls", "pfbo", "oio", "lbd", "ve", "m"];

  const handleClearFilter = (id) => {
    dispatch(removeLocalFilter(id));
  };

  const handleOldRemoveFilter = (id) => {
    dispatch(removeFilterById(id));
  };

  
  // useEffect(() => {
  //   const handleClickOutside = event => {
  //     if (showAllDropdownRef.current && !showAllDropdownRef.current.contains(event.target)) {
  //       setOpen(false);
  //     }
  //   };
  //   document.addEventListener("click", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);

  return ( 
    <div ref={showAllDropdownRef} className={css.showAll} name="dropdown-btn">
    {isOpen && list && (
      <div className={classNames(css.clsPopupDropdown)} >
      <div className={newsearchfilter.clsShowAllMenuPopup}>
        <div className={newsearchfilter.clsInnerScroll}>
          <ul className={newsearchfilter.clsFilterModule}>
            {list[sectionKey] && (
              <li className={newsearchfilter.clsFilterModuleList} key={sectionKey}>
                {sectionKey === "ll" ? renderleadList(list[sectionKey]) : renderGroupItems(list[sectionKey])}
              </li>
            )}
          </ul>
        </div>
      </div>
      </div>
     )}
  </div>
  );
};

export default ShowAllButton;
