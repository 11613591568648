import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Field from './Field';
import css from './style.scss';


class FieldSet extends PureComponent {

  constructor(props) {
    super(props);

  }

  onSelectedValue (type, value) {
    const { name, search, onSelectValue } = this.props;

    const minValue = search.get(`${name}Min`, '');
    const maxValue = search.get(`${name}Max`, '');

    if (type === 'min' && value !== minValue) {

      onSelectValue(value, null, 'min');
    } else if (type === 'max' && value !== maxValue) {
      onSelectValue(null, value, 'max');
    }
  };

  render() {
    const { name, fieldMap, label, placeholder, containerClass, maxLength, fullRow, listType, onSelectValue } = this.props;
    const field = fieldMap[name];
    const { range, tooltip, description } = field;
    const effectiveLabel = `${label || field.label}${maxLength ? ` (Limit: ${maxLength})` : ''}`;

    return (
      <div className={classNames(containerClass, { [css.partDouble]: fullRow })} title={tooltip}>
        {!effectiveLabel ? null : <div className={containerClass === css.section ? css.sectionCaption : css.partCaption}>{effectiveLabel}</div>}
        <Field {...this.props} onChangeValue={(value) => onSelectValue ? this.onSelectedValue('min', value) : null} options={this.props.optionsMin ? this.props.optionsMin : this.props.options} placeholder={range ? 'Min' : placeholder} name={`${name}${range ? 'Min' : ''}`} field={{...field, type: !listType ? field.type : listType}} />
        {!range ? null : <span className={css.divider}>to</span>}
        {!range ? null : <Field {...this.props} onChangeValue={(value) => onSelectValue ? this.onSelectedValue('max', value) : null} options={this.props.optionsMax ? this.props.optionsMax : this.props.options} placeholder={range ? 'Max' : placeholder} name={`${name}${range ? 'Max' : ''}`} field={{...field, type: !listType ? field.type : listType}} />}
        {!description ? null : <div className={css.subText}>{description}</div>}
      </div>
    );
  }
}

FieldSet.propType = {
  range: PropTypes.bool,
  fullRow: PropTypes.bool,
  containerClass: PropTypes.string,
};

FieldSet.defaultProps = {
  range: false,
  fullRow: false,
  containerClass: css.part,
};

export default FieldSet;
