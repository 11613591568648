

import classNames from "classnames";
import React, { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap/lib";
import { useDispatch, useSelector } from "react-redux";
import {
  removeFilterById,
  removeLocalFilter,
  selectNewFilter,
  selectShowAllEditOption,
  updateFilter,
  updateLocalFilter,
  selectLocalFilter
} from "../../../../../../data/search";
import newsearchfilter from "../../SearchFilterNew.scss"; // Import the SCSS file
import FilterComboBox from "../../shared/Autosearch/FilterComboBox";
import { DefaultAmountOptionsList, FilterExtraTextDisplay, ForeclosureDateOptions, ForeclosureStatusExcludeOptions, ForeclosureStatusIncludeOptions, OpeningAmountOptionsList, PreForeclosureList, fieldTagsMap, formatDateShortWithUTC } from "../../shared/ConstantsNew";
import SelectAnyoneByMinMax from "../../shared/SelectAnyoneDate/SelectAnyoneByMinMax";
import ToggleSection from "../../shared/ToggleSection";
import ToggleList from "../../shared/ToggleSection/ToggleList";
import { useFilter } from "../../TagFilterContext";
import moment from "moment";
import { generateMinMaxDateValue, generateMinMaxValue, getMinMaxDateToUpdate, getMinMaxValuesToUpdate } from "../../../../../../utils/filter_menu/minandmax";

const PreForeclosureBank = () => {
  const getLocalFilter = useSelector(selectLocalFilter);
  const { selectedTags, filteredFields, navIndex } = useFilter();
  const [isFilterApplied, setFilterApplied] = useState(false);
  const refs = useRef({});

  const dispatch = useDispatch();
  const filter = useSelector(selectNewFilter);
  const [pfbo, setPBboValues] = useState({
    foreclosureStatus2Class: null,
    foreclosureStatus2: null,
    foreclosureRecordingDateMin: null,
    foreclosureRecordingDateMax: null,
    auctionDateMin: null,
    auctionDateMax: null,
    foreclosureReleaseDateMin: null,
    foreclosureReleaseDateMax: null,
    defaultAmountMin: null,
    defaultAmountMax: null,
    openingBidAmountMin: null,
    openingBidAmountMax: null,
    preforeclosureIncluded: null
  });

  const getBooleanOption = (value, options) => {
    return options.find(option => option.value === value) || null;
  };

  useEffect(() => {
    if (filter && JSON.stringify(filter) !== JSON.stringify(pfbo)) {
      setPBboValues(filter);
      setFilterApplied(false);
      setTimeout(() => setFilterApplied(true), 0);
    }
  }, [filter]); 
  
  useEffect(() => {
    if (isFilterApplied) {
      applyFiltersBasedOnPreForeclosure();
    }
  }, [isFilterApplied, pfbo]); 

  const prevPfboValues = useRef(null); // Store previously applied values

  const applyFiltersBasedOnPreForeclosure = () => {
    if (prevPfboValues.current && JSON.stringify(prevPfboValues.current) === JSON.stringify(pfbo)) {
      return; // Prevent unnecessary updates if nothing changed
    }
  
    prevPfboValues.current = pfbo; // Update reference with current state
  
    const fieldsToCheck = [
      { label: "Pre-Foreclosure", value: pfbo.foreclosureStatus2Class, key: "foreclosureStatus2Class", options: PreForeclosureList, isBool: true },
      { label: "Pre-Foreclosure Types", value: pfbo.foreclosureStatus2, key: "foreclosureStatus2", isMultiSelect: true, options: ForeclosureStatusIncludeOptions },
      { label: "Recording Date", min: pfbo.foreclosureRecordingDateMin, max: pfbo.foreclosureRecordingDateMax, key: "foreclosureRecordingDate", isDate: true },
      { label: "Auction Date", min: pfbo.auctionDateMin, max: pfbo.auctionDateMax, key: "auctionDate", isDate: true },
      { label: "Pre-Foreclosure Release Date", min: pfbo.foreclosureReleaseDateMin, max: pfbo.foreclosureReleaseDateMax, key: "foreclosureReleaseDate", isDate: true },
      { label: "Opening Bid Amount", min: pfbo.openingBidAmountMin, max: pfbo.openingBidAmountMax, key: "openingBidAmount" },
      { label: "Default Amount", min: pfbo.defaultAmountMin, max: pfbo.defaultAmountMax, key: "defaultAmount" },
    ];
  
    fieldsToCheck.forEach(({ label, min, max, key, isBool, isDate, value, options, isMultiSelect }) => {
      if (isBool) {
        const option = getBooleanOption(value, options);
        if (option) {
          handleBooleanSelectPCNew(option, key, label);
        } else {
          handleClearFilter(label);
        }
      } 
      else if (isMultiSelect) {
        const selectedValues = value ? value.split(',') : [];
        const selectedOptions = options.filter(option => selectedValues.includes(option.id));
  
        if (selectedOptions.length) {
          handleSelectAllClick(selectedOptions, label, key);
        } else {
          handleClearFilter(label);
        }
      }
      else if (isDate) {
        if ((min !== null && min !== '' && min !== undefined) || (max !== null && max !== '' && max !== undefined)) {
          if (prevPfboValues.current?.[key] !== min || prevPfboValues.current?.[key] !== max) {
            comboDateValueChange(min, max, label, key);
          }
        }
      } 
      else {
        if ((min !== null && min !== '' && min !== undefined) || (max !== null && max !== '' && max !== undefined)) {
          if (prevPfboValues.current?.[key] !== min || prevPfboValues.current?.[key] !== max) {
            comboBoxValueChange(min, max, label, key);
          }
        }
      }
    });
  };

  const getEditOptions = useSelector(selectShowAllEditOption)

  useEffect(() => {
    if (getEditOptions) {
      if (getEditOptions?.key === 'pfbo') {
        let key = getEditOptions?.apiKey.replace(/Max|Min/g, "")
        editSectionClick(key ? key : '')
      }
    }
  }, [getEditOptions]);


  const editSectionClick = (id) => {
    setTimeout(() => {
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 200); // 5000 milliseconds = 5 seconds
  };

  const stringToBoolean = (str) => {
    // Convert "true" to true (case insensitive)
    if (str === "true") {
      return true;
    }
    // Convert "false" to false (case insensitive)
    else if (str === "false") {
      return false;
    }
    // For any other string, return undefined or handle accordingly
    else {
      return str;
    }
  }

  const handleOptionSelectPCNew = (option, key, leftLabel) => {
    // add again
    updateFilterOldStore(key, option.value)
    updateFilterStore(option, key, leftLabel)

  };

  const handleBooleanSelectPCNew = (option, key, leftLabel) => {
    const preforeclosureIncluded =  option.value === 'PreForeclosure' ? true : option.value === 'PreForeclosureExclude' ? false : null;
    if (typeof preforeclosureIncluded === 'boolean') {
      updateFilterOldStore('preforeclosureIncluded', preforeclosureIncluded)
    }
    // add again
    if (option.value === true || option.value === false || option.value !== '') {
      updateFilterOldStore(key, option.value)
      updateFilterStore(option, key, leftLabel)
    } else {
      handleOldRemoveFilter(key)
      handleOldRemoveFilter('preforeclosureIncluded')
      handleClearFilter(leftLabel)
    }

  };

  // combox
  const comboBoxValueChange = (minValue, maxValue, leftLabel, key) => {
   
    const name = generateMinMaxValue(minValue, maxValue);
    const valuesToUpdate = getMinMaxValuesToUpdate(minValue, maxValue, key);

    valuesToUpdate.forEach(({ key, value }) => {
      updateFilterOldStore(key, value);
    });

    updateFilterStoreCombox(name, key, leftLabel)
  };

  const handleSelectAllClick = (selectedOptions, leftValue, key) => {
    let name = ''
    if (selectedOptions?.length) {
      if (selectedOptions?.length > 1) {
        name = `${selectedOptions[0]?.name}  ( ${selectedOptions.length} )`;
      } else {
        name = `${selectedOptions[0]?.name}`;
      }
      const selectedIds = selectedOptions.map(element => element.id).join(',')
      const fullSeletedNamesList = selectedOptions.map(element => element.name).join(',')
      updateFilterOldStore(key, selectedIds)
      // meed to work morning
      updateFilterStore({ value: key, label: name }, key, leftValue, fullSeletedNamesList);
    } else {
      handleOldRemoveFilter(key)
      handleClearFilter(leftValue)
    }
  };

  // date
  // combo date
  const comboDateValueChange = (minValue, maxValue, leftLabel, key) => {

    const name = generateMinMaxDateValue(minValue, maxValue);
    const valuesToUpdate = getMinMaxDateToUpdate(minValue, maxValue, key);

    valuesToUpdate.forEach(({ key, value }) => {
      updateFilterOldStore(key, value);
    });

    updateFilterStoreCombox(name, key, leftLabel);
  };
  // store 
  const updateFilterOldStore = (key, id) => {
    // if (id) {
    dispatch(updateFilter({ [key]: id }));
    // }
  };

  const updateFilterStore = (option, key, leftLabel, fullSeletedNamesList) => {
    // if (option.value) {
    option.value = stringToBoolean(option.value)
    dispatch(updateLocalFilter([{
      key: 'pfbo', id: leftLabel, name: option.label, apiKey: key, [key]: option.value, removeID: key,
      showALLDisplayValue: fullSeletedNamesList
    }]));
    // }
  };

  const updateFilterStoreCombox = (name, key, leftLabel) => {
    dispatch(updateLocalFilter([{
      key: 'pfbo', id: leftLabel, name: name, apiKey: key, removeID: key,
      showALLDisplayValue: name
    }]));
  };

  const handleClearFilter = (id) => {
    dispatch(removeLocalFilter(id));
  };

  const handleOldRemoveFilter = (id) => {
    dispatch(removeFilterById(id));
  };

  const getClassNames = (fieldLabel) => {
    const tagsForField = getTagsForField(fieldLabel);
    const PreForeclosureFilters = getLocalFilter.filter(filter => filter.key === 'pfbo')
    return `${newsearchfilter.clsShowOnTag}`;
  };

  const getTagsForField = (fieldLabel) => {
    return fieldTagsMap[fieldLabel] || [];
  };

  const isFilteredSection = (fieldLabel) => {
    const tagsForField = getTagsForField(fieldLabel);
    return selectedTags.length > 0 && tagsForField.some(tag => selectedTags.includes(tag));
  };

  useEffect(() => {
    const highlightedField = Object.keys(filteredFields).find(field => filteredFields[field] === navIndex);
    if (highlightedField && refs.current[highlightedField]) {
      refs.current[highlightedField].focus({ preventScroll: true });
      refs.current[highlightedField].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [filteredFields, navIndex]);

  const getHeaderClassName = isFilteredSection("Pre-Foreclosure") ? newsearchfilter.filteredSubTitle : "";
  const isHighlightedIndex = filteredFields['Pre-Foreclosure'] === navIndex ? true : false;

  return (
    <div className={classNames(
      getClassNames('Pre-Foreclosure'),
      getClassNames('Recording Date'),
      getClassNames('Auction Date'),
      getClassNames('Pre-Foreclosure Release Date'),
      getClassNames('Opening Bid Amount'),
      getClassNames('Default Amount')
    )}>
      {/* <div className={newsearchfilter.clsRightBodyScroll}> */}
      <div className={newsearchfilter.clsContentBody}>
        <div className={newsearchfilter.headerBg}>
          <h2 className={newsearchfilter.clsHeading}>Pre-Foreclosure & Bank Owned</h2>
        </div>
        <div className={newsearchfilter.pb_28}>
          {/* <h4 className={classNames(newsearchfilter.clsSubHeading, getClassNames("Pre-Foreclosure"))}>Pre-Foreclosure</h4> */}
          <h4 className={classNames((getHeaderClassName === "" ? newsearchfilter.clsSubHeading : getHeaderClassName), (isHighlightedIndex ? newsearchfilter.highlightOrange : ""))}>Pre-Foreclosure</h4>
          <div id="foreclosureStatus2Class" className={classNames(pfbo.foreclosureStatus2Class ? newsearchfilter.clsBgGray : '', getClassNames("Pre-Foreclosure"))}
            ref={el => refs.current['Pre-Foreclosure'] = el}>
            <div id="foreclosureStatus2Class" className={newsearchfilter.clsBgGray}>
            <div className={pfbo.foreclosureStatus2Class ? newsearchfilter.pb_28 : ''}>
              <ToggleList
                headerTitle={'Pre-Foreclosure'}
                headerTitleShow={false}
                backGroudGray={false}
                selected={pfbo.foreclosureStatus2Class}
                options={PreForeclosureList}
                isHighlightIndex={filteredFields['Pre-Foreclosure'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Pre-Foreclosure") ? newsearchfilter.filteredSubTitle : ""}
                onSelectOption={(selectedOption) => {
                  // reset the foreclose types when Foreclosure class tab is modified
                  handleOldRemoveFilter("foreclosureStatus2");
                  handleClearFilter("Pre-Foreclosure Types");
                  handleOldRemoveFilter('preforeclosureIncluded')
                  
                  handleBooleanSelectPCNew(
                    selectedOption,
                    'foreclosureStatus2Class',
                    'Pre-Foreclosure'
                  )}
                }
              />
            </div>

            <div id="foreclosureStatus2">
              <div className={pfbo.foreclosureStatus2Class ? newsearchfilter.pb_28 : ''}>
                <ToggleSection
                  headerTitle={'Pre-Foreclosure Types'}
                  options={pfbo.foreclosureStatus2Class === 'PreForeclosure' ? ForeclosureStatusIncludeOptions : pfbo.foreclosureStatus2Class === 'PreForeclosureExclude' ? ForeclosureStatusExcludeOptions : []}
                  selectedIds={pfbo.foreclosureStatus2}
                  onSelectClickAction={(selectedOptions) => {
                    handleSelectAllClick(
                      selectedOptions,
                      'Pre-Foreclosure Types',
                      "foreclosureStatus2"
                    )
                  }
                  }
                />
                {!pfbo.foreclosureStatus2Class && <FilterExtraTextDisplay />}
              </div>
            </div>
          </div>
        </div>
        </div>

        <div id="foreclosureRecordingDate" ref={el => refs.current['Recording Date'] = el} className={classNames(newsearchfilter.pb_28, getClassNames("Recording Date"))}>
          <SelectAnyoneByMinMax
            headerTitle={'Recording Date'}
            box2Placeholder={'Select Range'}
            isHighlightIndex={filteredFields['Recording Date'] === navIndex ? true : false}
            headerClassName={isFilteredSection("Recording Date") ? newsearchfilter.filteredSubTitle : ""}
            defaultValues={{
              min: pfbo.foreclosureRecordingDateMin,
              max: pfbo.foreclosureRecordingDateMax
            }}
            onValueChange={(fromDate, toDate) => {
              if (fromDate || toDate) {
                comboDateValueChange(fromDate, toDate, 'Recording Date', 'foreclosureRecordingDate')
              } else {
                handleOldRemoveFilter('foreclosureRecordingDate')
                handleClearFilter('Recording Date')
              }
            }}
            orText={'or'}
            options={ForeclosureDateOptions}
          />

        </div>


        <div id="auctionDate" ref={el => refs.current['Auction Date'] = el} className={classNames(newsearchfilter.pb_28, getClassNames("Auction Date"))}>
          <SelectAnyoneByMinMax
            headerTitle={'Auction Date'}
            box2Placeholder={'Select Range'}
            isHighlightIndex={filteredFields['Auction Date'] === navIndex ? true : false}
            headerClassName={isFilteredSection("Auction Date") ? newsearchfilter.filteredSubTitle : ""}
            defaultValues={{
              min: pfbo.auctionDateMin,
              max: pfbo.auctionDateMax
            }}
            onValueChange={(fromDate, toDate) => {
              if (fromDate || toDate) {
                comboDateValueChange(fromDate, toDate, 'Auction Date', 'auctionDate')
              } else {
                handleOldRemoveFilter('auctionDate')
                handleClearFilter('Auction Date')
              }
            }}
            orText={'or'}
            options={ForeclosureDateOptions}
          />

        </div>

        <div id="foreclosureReleaseDate" ref={el => refs.current['Pre-Foreclosure Release Date'] = el} className={classNames(newsearchfilter.pb_28, getClassNames("Pre-Foreclosure Release Date"))}>
          <SelectAnyoneByMinMax
            headerTitle={'Pre-Foreclosure Release Date'}
            box2Placeholder={'Select Range'}
            isHighlightIndex={filteredFields['Pre-Foreclosure Release Date'] === navIndex ? true : false}
            headerClassName={isFilteredSection("Pre-Foreclosure Release Date") ? newsearchfilter.filteredSubTitle : ""}
            defaultValues={{
              min: pfbo.foreclosureReleaseDateMin,
              max: pfbo.foreclosureReleaseDateMax
            }}
            onValueChange={(fromDate, toDate) => {
              if (fromDate || toDate) {
                comboDateValueChange(fromDate, toDate, 'Pre-Foreclosure Release Date', 'foreclosureReleaseDate')
              } else {
                handleOldRemoveFilter('foreclosureReleaseDate')
                handleClearFilter('Pre-Foreclosure Release Date')
              }
            }}
            orText={'or'}
            options={ForeclosureDateOptions}
          />

        </div>


        <div id="openingBidAmount" ref={el => refs.current['Opening Bid Amount'] = el} className={classNames(newsearchfilter.pb_28, getClassNames("Opening Bid Amount"), getClassNames("Default Amount"))}>
          <Row>
            <Col md={6} xs={12} className={getClassNames("Opening Bid Amount")}>
              <FilterComboBox
                boxTittle={"Opening Bid Amount"}
                minOptions={OpeningAmountOptionsList}
                minLabel={"Min"}
                maxLabel={"Max"}
                maxOptions={OpeningAmountOptionsList}
                isHighlightIndex={filteredFields['Opening Bid Amount'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Opening Bid Amount") ? newsearchfilter.filteredSubTitle : ""}
                defaultValues={{ min: pfbo.openingBidAmountMin, max: pfbo.openingBidAmountMax }}
                onSelectValue={(minValue, maxValue) =>
                  comboBoxValueChange(minValue, maxValue, "Opening Bid Amount", "openingBidAmount")
                }
              />
            </Col>
            <Col md={6} xs={12} className={getClassNames("Default Amount")}>
              <div id="defaultAmount" ref={el => refs.current['Default Amount'] = el}></div>
              <FilterComboBox
                boxTittle={"Default Amount"}
                minOptions={DefaultAmountOptionsList}
                minLabel={"Min"}
                maxLabel={"Max"}
                maxOptions={DefaultAmountOptionsList}
                isHighlightIndex={filteredFields['Default Amount'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Default Amount") ? newsearchfilter.filteredSubTitle : ""}
                defaultValues={{ min: pfbo.defaultAmountMin, max: pfbo.defaultAmountMax }}
                onSelectValue={(minValue, maxValue) =>
                  comboBoxValueChange(minValue, maxValue, "Default Amount", "defaultAmount")
                }
              />
            </Col>
          </Row>
        </div>


        {/* </div> */}
      </div>
    </div>
    // </div>
  );
};

export default PreForeclosureBank;
