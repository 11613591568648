import classNames from "classnames";
import Button from "components/base/Button";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import newsearchfilter from "../SearchFilterNew.scss";
import { selectLocalFilter, selectShowAllEditOption, clearButtonEditOption, selectFilterByKey } from "../../../../../data/search";
import SVG from "components/base/SVG";
import { useDispatch, useSelector } from "react-redux";
import { leftPanelCategories } from "../shared/ConstantsNew";
import { useActiveSection } from "./ActiveSectionContext";
import ShowAllButton from "../showAllButton";
import { LeadListScreen } from "../shared/ConstantsNew";

export const LeftPanel = ({ onItemClick }) => {
  const dispatch = useDispatch();
  const { activeSection, setActiveSection } = useActiveSection();
  const { openSections, setOpenSections, allSectionsOpen, toggleAllSections, setAllSectionsOpen, saveStateToLocalStorage } = useActiveSection();
  const getLocalFilter = useSelector(selectLocalFilter);

  console.log(getLocalFilter,'getLocalFilter')
  const getEditOptions = useSelector(selectShowAllEditOption);
  const handleItemClick = index => {
    setActiveSection(index); // Set the clicked item as active
    onItemClick(index);
  };

  const getSelectFilterlistingType = useSelector(state =>
    selectFilterByKey(state, LeadListScreen?.fields?.label)
  );

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("openSections");
      localStorage.removeItem("allSectionsOpen");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const toggleDropdown = (sectionKey) => {
    setOpenSections(prevState => ({
      ...prevState,
      [sectionKey]: !prevState[sectionKey]
    }));
  };

  const disableShowAllButton = () => {
    // Disable button if no item is selected
    return !anyItemSelected();
  };

  const anyItemSelected = () => {
    // Iterate through left panel categories
    for (let item of leftPanelCategories) {
      if (getSelectedCount(item.key) !== 0) {
        return true; // Found an item with selected count > 0
      }
    }
    return false; // No item with selected count > 0 found
  };

  useEffect(() => {
    const savedOpenSections = JSON.parse(localStorage.getItem("openSections"));
    const savedAllSectionsOpen = JSON.parse(localStorage.getItem("allSectionsOpen"));

    if (savedOpenSections && savedAllSectionsOpen !== null) {
      setOpenSections(savedOpenSections);
      setAllSectionsOpen(savedAllSectionsOpen);
    }
  }, []);

  useEffect(() => {
    saveStateToLocalStorage(openSections, allSectionsOpen);
  }, [openSections, allSectionsOpen]);


  const getSelectedCount = key => {
    return getLocalFilter && getLocalFilter?.reduce((count, list) => {
      return list.key === key ? count + 1 : count;
    }, 0);
  };

  useEffect(() => {
    if (getEditOptions) {
      if (getEditOptions?.key) {
        editActionClick(getEditOptions?.key)
      }
    }
  }, [getEditOptions, activeSection]);

  const editActionClick = (id) => {
    const leftPanelSelected = document.getElementById(`${id}`);
    if (leftPanelSelected) {
      leftPanelSelected.click();
    }
  }

  return (
    <>
      <ul className={newsearchfilter.clsMenuList}>
        {leftPanelCategories && leftPanelCategories?.map((item, index) => {
          const isLastItem = index === leftPanelCategories.length - 1; // Check if it's the last item
          return (
            <li
              id={item.key}
              className={classNames(newsearchfilter.clsContent, newsearchfilter.addbgColor, {
                [newsearchfilter.active]: activeSection === index,
                [newsearchfilter.lastItem]: isLastItem // Apply the lastItem class if it's the last item
              })}
              key={index}
            >
              <div className={classNames({
                [newsearchfilter.innerListItem]: getSelectedCount(item.key) === 0,
                [newsearchfilter.innerListItemActive]: getSelectedCount(item.key) !== 0
              })}>
                <div className={newsearchfilter.arrowSection}>
                  {getSelectedCount(item.key) !== 0 && (
                    <div className={newsearchfilter.clsContent} onClick={() => toggleDropdown(item.key)}>
                      <SVG
                        icon={openSections[item.key] ? "iconCaretDown" : "iconCaretLeft"} // Change the icon dynamically
                        className={newsearchfilter.dropdownCaretIconNew}
                      />
                    </div>
                  )}
                  <div className={newsearchfilter.clsContent} onClick={() => handleItemClick(index)}>
                    <h4>{item?.name}</h4>
                    <p>{item?.description}</p>
                  </div>
                </div>
                {getSelectedCount(item.key) !== 0 && (
                  <p className={newsearchfilter.clsCounter}>{getSelectedCount(item.key)}</p>
                )}
              </div>
              <div>
                {getSelectedCount(item.key) !== 0 && openSections[item.key] && (
                  <ShowAllButton sectionKey={item.key} />
                )}
              </div>
            </li>
          );
        })}
      </ul>
      <div className={newsearchfilter.fixedShowAll}>
        <div className={newsearchfilter.fullWidthBtn}>
          <Button
            kind="link-default"
            size="large"
            className={newsearchfilter.btnShowAllFilters}
            disabled={disableShowAllButton()}
            onClick={toggleAllSections}
          >
            {allSectionsOpen ? "Hide All Applied Filters" : "Show All Applied Filters"}
          </Button>
        </div>
      </div>
    </>
  );
};

LeftPanel.propTypes = {
  onItemClick: PropTypes.func.isRequired
};
