import React, { PureComponent, useCallback, useState, useEffect, useRef, memo } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fromJS } from 'immutable';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// import loadable from 'components/hoc/loadable';
import Table, { Column } from 'components/base/Table';
import Checkbox from 'components/base/Checkbox';
import CheckboxNew from 'components/base/Checkbox';
import SVG from 'components/base/SVG';
import Button from 'components/base/Button';
import { join } from 'utils/string';
import { dragStartWrapper } from 'utils/DOM/dragDrop';
import ColumnFilter from './ColumnFilter';
import css from './style.scss';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { AgGridReact } from 'ag-grid-react';
import LoadablePanel from 'components/hoc/loadable/Panel';
import { idMap, idMapGrid,satusRenderer, typeRenderer, groupIdRenderer, groupIdGridRenderer, idRenderer } from '.';
import { getOptionsNoDefault } from 'utils/normalizers/dropdown';
import { GridCheckboxDropdown } from 'components/base/Dropdown';
import { useSelector } from 'react-redux';
import {
  selectLoading,
  selectGroupContext
} from 'data/contacts';
import { MAIN_CONTEXT } from 'reducers';

//export const idMap = list => list.reduce((m, g) => ({ ...m, [g.get('id')]: g }), {});

//export const idRenderer = map => val => (val ? map[val].get('name') : null);

// export const groupIdRenderer = groupMap => val => (val ? val.split(',').filter((id, i, ar) => groupMap[id] && ar.indexOf(id) === i).reduce((names, id) => join(names, groupMap[id].get('name')), '') : null);

export const linkRenderer = url => (value, _, data) => <Link to={`${url}/${data.get('id')}`} className={css.link}>{value}</Link>;

export const multilineRenderer = value => (value || '').split('\n').map(line => <div key={line}>{line}</div>);

const nameRenderer2 = url => (props) => <Link to={`${url}/${props.data.id}`} className={css.link}>{props.data.name}</Link>;

const CustomCheckbox = React.memo((props) => {
  const { node, api } = props;
  const inputRef = useRef(null)

  useEffect(() => {
    node.setSelected(node.data.selected);
  },[])

  const handleCheckboxChange = useCallback(
    (e) => {
      const isChecked = e.target.checked;
      // Ensure `node` is valid before updating
      if (node.data.selected) {
        // Update the `selected` field in the row data
        node.data.selected = isChecked; // Directly update the data field
      }
        // Optionally synchronize with AG Grid's selection state
        node.setSelected(isChecked);
    },
    [node]
  );
  const checkboxId = `checkbox-${node.rowIndex}`;

  return (
    <input
      ref={inputRef} 
      id={checkboxId}
      type="checkbox"
      checked={node.data.selected}
     // checked={node.isSelected()}
      onChange={handleCheckboxChange}
    />
  );
});

const GroupHeader = (props) => {
  const { displayName, handleToggleFilter, gridcontext } = props;
  const toggleFilter = gridcontext.get('filterEnabled');

  return (
    <div className="custom-header">
      <span className="header-title">{displayName}</span>
      <span className="header-icon" onClick={handleToggleFilter}>
        <SVG icon={`${toggleFilter ? 'iconFilterFull' : 'iconFilter'}`} fill="#5897F5" width="60" />
      </span>
    </div>
  );
};


const ColumnFloatingFilter = ({
  column,
  values,
  modes,
  searchable,
  valueSep = ',',
  disabled,
  gridcontext,
  readOnly,
  handleFilterChange,
}) => {
  const loading = useSelector(selectLoading);
  const groupContext = useSelector(selectGroupContext(MAIN_CONTEXT));

  const filters = groupContext.get('filters');
  const value = filters.get(column.colId) || null;

  const handleChange = (ev) => {
    const newVal = ev.target?.value || ev.value;
    handleFilterChange(column.colId, newVal);
  };

  const props = {
    value: value || '',
    onClick: (event) => event?.stopPropagation(),
    onChange: handleChange,
    disabled,
    readOnly,
    name: column.colId,
    gridcontext:groupContext,
    loading,
  };

  return (
    <GridCheckboxDropdown
      {...props}
      options={values}
      modes={modes}
      searchable={searchable}
      valueSep={valueSep}
    />
  );
};


const CustomTextInputFloatingFilter = memo((props) => {
  const { handleFilterChange } = props;
  const [searchValue, setSearchValue] = useState('');
  const inputRef = useRef(null); // Reference to the input element
  const debounceTimeout = useRef(null); // To debounce the filter update

  const onSearchChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);

    clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(() => {
      props.parentFilterInstance((filterInstance) => {
        if (value) {
          filterInstance.setModel({
            type: 'contains',
            filter: value,
          });
        } else {
          filterInstance.setModel(null); // Clear the filter
        }
      });

      handleFilterChange(props.column.colId, value);
    }, 300); // Adjust debounce delay as needed
  };

  // useEffect(() => {
  //   if (inputRef.current) {
  //     inputRef.current.focus();
  //   }
  // }, [searchValue]);
  const inputId = `column_${props.column.colId}`;

  return (
    <input
      ref={inputRef}
      id={inputId}
      type="text"
      value={searchValue}
      onChange={onSearchChange}
      style={{
        width: '100%',
        padding: '5px',
        boxSizing: 'border-box',
      }}
    />
  );
});


const defaultPosition = { position: 'relative' };

const modes = 'Any,None,All'.split(',').map(label => ({ label, value: label.toUpperCase() }));
const modes2 = modes.slice(0, 2);

class ContactSearchGridTable extends PureComponent {
  /* :: selectCheckboxCellProps: Function */

  constructor(props) {
    super(props);

    this.handleSort = this.handleSort.bind(this);
    this.handleSelectClick = this.handleSelectClick.bind(this);
    this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
    this.handleSelectRemainingClick = this.handleSelectRemainingClick.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleToggleFilter = this.handleToggleFilter.bind(this);
    this.handleLoadNext = this.handleLoadNext.bind(this);
    this.handleLoadRemaining = this.handleLoadRemaining.bind(this);
    this.onSelectionChanged  = this.onSelectionChanged.bind(this);
    this.handleGridReady  = this.handleGridReady.bind(this);
    this.getGridProps     = this.getGridProps.bind(this);
    this.getColumnDefs    = this.getColumnDefs.bind(this)
    this.handleSortChanged = this.handleSortChanged.bind(this);
    this.customComparator  = this.customComparator.bind(this);

    this.state = {
      loadedContexts: {},
      position: defaultPosition,
      subject: null,
      columnApi: null,
      selectedIndices: [],
      isInitialized: false
    };

    this.gridProps = this.getGridProps();
    
    this.timer = null;
    this.searchContext = null;
    this.gridApi = React.createRef(null);

    this.agGridFields = this.getColumnDefs(props);

    window.addEventListener('resize', () => {
      setTimeout( () => {
        let headerContainer = document.querySelectorAll('.ag-header-container');
        let headerViewport  = document.querySelectorAll('.ag-header-viewport');
      
        if( headerViewport[1].getBoundingClientRect().width > (headerContainer[1].getBoundingClientRect().width + 1) ){
          this.gridApi?.current?.sizeColumnsToFit()
        }
      }, 100);
    });
  }

  customComparator(data1, data2) {
    return 0; //means no comparing and no sorting
  }

  getGridProps() {

    const {context} = this.props
    const toggleFilter = context.get('filterEnabled');

    return {
      onGridReady: this.onGridReady,
      //onColumnResized: this.handleColumnResized,
      onSelectionChanged: this.onSelectionChanged,
      onSortChanged: this.handleSortChanged,
      onGridSizeChanged: (params) => {
        setTimeout(() => {
          if (params.api) {
            //params?.api?.sizeColumnsToFit();
          }
        }, 100);
      },
      rowSelection: 'multiple',
      getRowNodeId: (d) => d.id,
      popupParent: document.body,
      suppressHeaderRefresh: true,
      suppressSorting: true,
      suppressMovableColumns: true,
      suppressMultiSort: true,
      suppressRowTransform: true,
      //getRowHeight: ({ node }) => (node && node.detail && 52) || 70,
      suppressCellSelection: true,
      suppressContextMenu: true,
      enableCellTextSelection: true,
      cacheBlockSize: 500,
      blockLoadDebounceMillis: 150,
      maxConcurrentDatasourceRequests: 2,
      defaultColDef: {
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: toggleFilter, // Use toggleFilter from props
        sortable: true,
        menuTabs: ['filterMenuTab'],
      },
      masterDetail: true,
      headerHeight: 40,
      detailCellRenderer: 'detailCellRenderer',
      groupSuppressRow: true,
      getRowStyle: ({ data: { type } = {} }) => ({
        backgroundColor: { U: '#e4f3e6', M: '#e8f1fe' }[type],
      }),
      frameworkComponents: {},
      icons: {
        sortAscending: '<i class="fa fa-sort-asc"></i>',
        sortDescending: '<i class="fa fa-sort-desc"></i>',
      },
      getRowClass: (params) => {
        if (params.data?.assigned) {
          return "row-assigned"; // Apply 'row-warning' for scores below 60
        } 
        return null; // No class for other rows
      }
    };
  }

  getColumnDefs( props ) {

    const { context, fullMode, groups, types, statuses, loading, onSelect, viewMode } = props;
    const selectEnabled = !!onSelect && !viewMode;


    const getColumnWidth = (field, defaultWidth) => {
      const savedWidth = localStorage.getItem(`columnWidth_${field}`);
      return savedWidth ? parseInt(savedWidth) : defaultWidth;
  };

    return [

      {
        headerCheckboxSelection: false,
        checkboxSelection: false ,
        headerName: "Column Name",
        field: "fieldName",
        width: localStorage.getItem("columnWidth_fieldName") 
               ? parseInt(localStorage.getItem("columnWidth_fieldName")) 
               : 50, 
        cellRendererFramework: (props) => {
          const { node, rowIndex } = props;
          
          return (
            <CheckboxNew checked={node.data.selected} onClick={() => {
              this.handleSelectClick(node.data.selected, {
                "row": rowIndex,
                "column": 0,
                "fieldPath": [
                    "selected"
                ]
              }, new Map(Object.entries(node.data)))}
              //return null
            } />
        )},
        headerComponentFramework: (props) => {
          const { node, rowIndex } = props;
          
          return (
            <CheckboxNew checked={context.get('allSelected')} onClick={this.handleSelectAllClick} />
          )},
        suppressCellContainer: true,
        filter: false,
        pinned: "left",
        sortable: false,
        hide: !selectEnabled
      },
      { field: "name", headerName: "Name", cellRenderer: fullMode ? nameRenderer2(`/contact/${context.get('id')}`) : undefined,
        floatingFilterComponent: CustomTextInputFloatingFilter,
        floatingFilterComponentParams: {
          handleFilterChange: this.handleFilterChange
        },
        autoHeight: true,
        wrapText: true,
        minWidth: getColumnWidth("name", 140),
     
        suppressSorting: true,
        suppressMultiSort: true,
        comparator: this.customComparator
      },
      { field: "mobilePhone", headerName: "Mobile", floatingFilterComponent: CustomTextInputFloatingFilter, floatingFilterComponentParams: {
        handleFilterChange: this.handleFilterChange
      },
        autoHeight: true,
        wrapText: true ,
        minWidth: getColumnWidth("name", 100),
        suppressSorting: true,
        suppressMultiSort: true,
        comparator: this.customComparator
      },
      { field: "landlinePhone", headerName: "Landline", floatingFilterComponent: CustomTextInputFloatingFilter, 
      floatingFilterComponentParams: {
        handleFilterChange: this.handleFilterChange,
        loading
      },
        autoHeight: true,
        wrapText: true,
        minWidth: getColumnWidth("name", 100),
        suppressSorting: true,
        suppressMultiSort: true,
        comparator: this.customComparator
      },
      { field: "otherPhone", headerName: "Other", floatingFilterComponent: CustomTextInputFloatingFilter,
        floatingFilterComponentParams: {
          handleFilterChange: this.handleFilterChange,
          loading
        },
        autoHeight: true,
        wrapText: true,
        suppressSorting: true,
        suppressMultiSort: true,
        comparator: this.customComparator
       },
      { field: "email", headerName: "Email", floatingFilterComponent: CustomTextInputFloatingFilter, 
        floatingFilterComponentParams: {
          handleFilterChange: this.handleFilterChange,
          loading
        },
        flex: 1,
        autoHeight: true,
        wrapText: true,
        minWidth: getColumnWidth("name", 150),
        suppressSorting: true,
        suppressMultiSort: true,
        comparator: this.customComparator
       },
      { field: "address", headerName: "Property Address", floatingFilterComponent: CustomTextInputFloatingFilter,
        floatingFilterComponentParams: {
          handleFilterChange: this.handleFilterChange,
          loading
        },
        autoHeight: true,
        wrapText: true,
        minWidth: getColumnWidth("name", 140),
        suppressSorting: true,
        suppressMultiSort: true,
        comparator: this.customComparator
      },
      { field: "mailAddress", headerName: "Mailing Address", floatingFilterComponent: CustomTextInputFloatingFilter,
        floatingFilterComponentParams: {
          handleFilterChange: this.handleFilterChange,
          loading
        },
        autoHeight: true,
        wrapText: true,
        minWidth: getColumnWidth("name", 140),
        suppressSorting: true,
        suppressMultiSort: true,
        comparator: this.customComparator
       },
      { field: "typeId", headerName: "Type",  cellRenderer: typeRenderer(idMapGrid(types)), 
        filter: "agMultiColumnFilter",
        floatingFilterComponent: ColumnFloatingFilter,
        floatingFilterComponentParams: {
          values: getOptionsNoDefault(types.toJS()),
          handleFilterChange: this.handleFilterChange,
          modes: modes2,
          gridcontext: context,
          loading
        },
        minWidth: getColumnWidth("name", 150),
        suppressSorting: true,
        suppressMultiSort: true,
        comparator: this.customComparator
      },
      { field: "statusId", headerName: "Status", cellRenderer: satusRenderer(idMapGrid(statuses)),          
        filter: "agMultiColumnFilter", 
        floatingFilterComponent: ColumnFloatingFilter,
        floatingFilterComponentParams: {
          values: getOptionsNoDefault(statuses.toJS()),
          handleFilterChange: this.handleFilterChange,
          modes: modes2,
          gridcontext: context,
          loading
        },
        suppressSorting: true,
        suppressMultiSort: true,
        minWidth: getColumnWidth("name", 150),
        comparator: this.customComparator,
        headerComponent: !fullMode ? GroupHeader : null,
        headerComponentParams: {
          handleToggleFilter: this.handleToggleFilter,
          gridcontext: context,
          loading
        }
      },
      { field: "groupIds", headerName: "Groups", sortable: false, headerComponent: GroupHeader, headerComponentParams: {
        handleToggleFilter: this.handleToggleFilter,
        gridcontext: context,
        loading
        }, cellRenderer: groupIdGridRenderer(idMapGrid(groups)) ,
        filter: "agSetColumnFilter", 
        floatingFilterComponent: ColumnFloatingFilter,
        floatingFilterComponentParams: {
          modes, searchable: true,
          values: getOptionsNoDefault(groups.toJS()),
          handleFilterChange: this.handleFilterChange,
          gridcontext: context,
          loading
        },
        gridOptions: {
          suppressMovableColumns: true
        },
        hide: !fullMode,
        // autoHeight: true,
        wrapText: true,
        suppressSizeToFit: true,
        flex: undefined,
        minWidth: getColumnWidth("name", 110),
      }
    ];
  }

  componentWillReceiveProps(props) {
    const { onSearch, context } = props;
    if (this.searchContext) {
      this.searchContext = null;
      onSearch(context);
    }
  }
  componentDidMount() {
    this.gridApi?.current?.hideOverlay();
    if (this.state.columnApi) {
      setTimeout(() => {
          this.state.columnApi.getAllColumns().forEach((col) => {
              const savedWidth = localStorage.getItem(`columnWidth_${col.getColId()}`);
              if (savedWidth) {
                  this.state.columnApi.setColumnWidth(col.getColId(), parseInt(savedWidth), true);
              }
          });
      }, 500);
  };
  this.applyStoredColumnWidths();
    
  }
  componentDidUpdate(prevProps, prevState) { 
    if (prevState.toggleFilter !== this.state.toggleFilter) {
      this.agGridFields = this.getColumnDefs(this.props);
      if (this.gridApi?.current) {
        this.gridApi?.current?.sizeColumnsToFit()
      }
    }

    if( prevProps.loading !== this.props.loading ){
      if(this.props.loading){
        this.gridApi?.current?.showLoadingOverlay()
      }
    }
  }

  handleSortChanged(params) {
    const columnState = params.columnApi.getColumnState();
    const activeSorts = columnState.filter(col => col.sort); // Only get active sort columns
    
    if (activeSorts.length) {
      const sortedField = activeSorts[0].colId;
      //const sortedOrder = activeSorts[0].sort;
      //console.log("Sorted Field: ", sortedField, "Sort Order: ", sortedOrder);
      this.handleSort({ fieldName: sortedField })
    }

  };

  killTimer() {
    if (this.timer) clearTimeout(this.timer);
    this.timer = null;
  }

  updateAndSearch(context) {
    this.searchContext = context;
    this.props.onUpdate(context);
  }

  handleSort({ fieldName: field = '' }) {
    const { context } = this.props;
    const cur = context.getIn(['sortFields', '0']);
    
    this.updateAndSearch(context.set('index',0).set('sortFields', fromJS([cur !== field ? field : `!${field}`])));
  }

  handleSelectClick(value, coords) {
    this.props.onSelect(!value, coords.row);
  }

  handleOnSelectClick(value, index) {
    this.props.onSelect(value, index);
  }

  handleSelectAllClick(ev) {
    this.props.onSelect(ev.target.checked);
    
    if(this.props.fullMode){
      this.gridApi?.current?.redrawRows();
    }
  }

  handleSelectRemainingClick(ev) {
    this.props.onSelect(ev.target.checked, -1);
  }

  


handleFilterChange(field, value) {
  const { onUpdate, onSearch, context } = this.props;
  const search = context.setIn(['filters', field], value);

  onUpdate(search);

  this.killTimer();
  this.timer = setTimeout(() => {
      this.killTimer();
      onSearch(this.props.context.set('index', 0));
  }, search.getIn(['info', 'data']) ? 300 : 1000);

  if (!this.state.columnApi) return;

  const allColumns = this.state.columnApi.getAllColumns();
  const colIndex = allColumns.findIndex((col) => col.getColId() === field);

  if (colIndex >= 0) {
      setTimeout(() => {
          const floatingFilterElements = document.querySelectorAll('.ag-floating-filter');
          if (!floatingFilterElements.length) {
             
              return;
          }

          const headerElement = floatingFilterElements[colIndex + 8];
          if (!headerElement) {
         
              return;
          }
          const filterBody = headerElement.querySelector('.placeholdertext');
          if (!filterBody) {
      
              return;
          }
           const filterText = filterBody.textContent || filterBody.innerText; 
          setTimeout(() => {
              if (filterText == 'All') {
                  const defaultWidth = 150; 
                  this.state.columnApi.setColumnWidth(field, defaultWidth, true);
                  localStorage.removeItem(`columnWidth_${field}`);

              } else {
                  const filterWidth = filterBody.getBoundingClientRect().width;
                  
                  if (filterWidth > 0) {
                      const adjustedWidth = filterWidth + 60;
                      this.state.columnApi.setColumnWidth(field, adjustedWidth, true);
                      localStorage.setItem(`columnWidth_${field}`, adjustedWidth);
                  }
              }

              setTimeout(() => {
                  const headerContainer = document.querySelectorAll('.ag-header-container');
                  const headerViewport = document.querySelectorAll('.ag-header-viewport');
                  
                  if (
                      headerViewport[1] &&
                      headerContainer[1] &&
                      headerViewport[1].getBoundingClientRect().width > headerContainer[1].getBoundingClientRect().width + 1
                  ) {
                      this.gridApi?.current?.sizeColumnsToFit();
                  }
              }, 300);
          }, 300);
      }, 500);
  }
}


  handleToggleFilter(ev) {
    const { context } = this.props;
    ev.stopPropagation();
    this.updateAndSearch(context.set('filterEnabled', !context.get('filterEnabled')));
    
    this.agGridFields = this.getColumnDefs(this.props);
    this.gridProps  = this.getGridProps();

    if (this.gridApi?.current) {
      this.gridApi.current.refreshHeader();
    }
  }

  handleLoadNext() {
    this.doLoadNext(this.props.context.get('limit'));
  }

  handleLoadRemaining() {
    this.doLoadNext(0);
  }

  doLoadNext(limit) {
    const { context, onSearch } = this.props;
    onSearch(context.merge({ index: context.get('data').size, limit }));
  }

  // Ag Grid
  

  onGridReady = (params) => {
    if (this.state.isInitialized) return;

    this.gridApi.current = params.api;

    this.setState({ columnApi: params.columnApi, isInitialized: true }, () => {
    
            this.applyStoredColumnWidths();
      
    });

    params.api.addEventListener('firstDataRendered', () => {
        params.api.sizeColumnsToFit();
    });

    params.api.hideOverlay();

    setTimeout(() => {
        params.api.sizeColumnsToFit();
    }, 100);
};

// ✅ Check if stored column widths exist before applying them
hasStoredColumnWidths = () => {
    const storedWidths = localStorage.getItem("columnWidths");
    return storedWidths && JSON.parse(storedWidths).length > 0;
};


applyStoredColumnWidths = () => {
    if (!this.state.columnApi) return;

    setTimeout(() => {
        this.state.columnApi.getAllColumns().forEach((col) => {
            const savedWidth = localStorage.getItem(`columnWidth_${col.getColId()}`);
            if (savedWidth) {
                this.state.columnApi.setColumnWidth(col.getColId(), parseInt(savedWidth), true);
            }
        });
    }, 500);
};




hasStoredColumnWidths = () => {
  const storedWidths = localStorage.getItem("columnWidths");
  return storedWidths && JSON.parse(storedWidths).length > 0;
};

  handleGridReady(params) {

    
    if (this.state.isInitialized) return
    // Select default rows by rowIndex
    const { context } = this.props;

    setTimeout( () => {
      this.setState({isInitialized:true});
    },100)
    
    params.api.addEventListener('firstDataRendered', () => {
      
      this.gridApi.current = params.api;
      this.setState({ columnApi: params.columnApi })

      //this.gridApi.refreshHeader();
      params.api?.sizeColumnsToFit()

    });

    params.api.hideOverlay();
    setTimeout( () => {
      params.api?.sizeColumnsToFit()
    },100)
  }

  handleColumnResized({ column }) {
    if (column) {
      const col = this.state.columns.find(c => c.field === column.colId);
      if (col) col.width = column.actualWidth;
    }
  }

  handleColumnDragStopped() {
    const { columns } = this.state;
    this.state.columnApi.getAllGridColumns().forEach(({ colId, pinned }, i) => {
      const col = columns.find(c => c.field === colId);
      if (col) {
        col.seq = i;
        col.pinned = pinned;
      }
    });
  }

  hackHandleIgnoreNextRowClick() {
    // TODO: Figure out why stopPropagation() don't work within grid cells
    this.hackIgnoreNextRowClick = true;
  }

  handleGridRowClicked({ api, node, data: { id } = {} }) {
    if (this.hackIgnoreNextRowClick) {
      setTimeout(() => {
        this.hackIgnoreNextRowClick = false;
      }, 1);
      return;
    }

    api.forEachNode((n) => {
      if (n !== node) n.setExpanded(false);
    });

    if (id) node.setExpanded(true);
  }

  onSelectionChanged(params) {
    const { context }     = this.props;
    const selectedNodes   = params.api.getSelectedNodes(); // data
    const allNodes        = params.api.getRenderedNodes();
    const selectedIndices = selectedNodes.map(node => node.id); // indexes
    const { isInitialized } = this.state;
    const previousIndices   = context.get('selection').toJS(); // IDs

    if(!isInitialized) return
    // Compare with previous state to find unselected indices
    
    const newlySelectedIndices = selectedNodes.filter((item) => !previousIndices.includes(Number
      (item.id))).map(node => node.rowIndex);

    // Find newly unselected indices
    const IndicesWantToRemove = previousIndices.filter(
        (index) => !selectedIndices.includes(String(index))
    );

    let unselectedIndices = [];
    
    if( IndicesWantToRemove.length > 0 ) unselectedIndices = allNodes.filter((item) => IndicesWantToRemove.includes(Number(item.id))).map(node => node.rowIndex);

    // Update state with the new selected indices
    this.setState({ selectedIndices });

    const selectedRows = params.api.getSelectedRows();
    const allRows      = params.api.getDisplayedRowCount();

    const isAllSelected   = selectedRows.length === allRows && selectedRows.length > 0;
    const isAllUnselected = selectedRows.length == 0;

    if( isAllSelected ){
      this.handleOnSelectClick(true);
    }else if( isAllUnselected ){
      this.handleOnSelectClick(false);
    }else if( unselectedIndices.length > 0 ){
      this.handleOnSelectClick( false, unselectedIndices[0])
    }else{
      this.handleOnSelectClick( true, newlySelectedIndices[0])
    }
    
  };

  render() {
    const { context, onSelect, fields, indexRenderer, className, viewMode, onDragStart, onDragEnd, fullMode, groups, loading, types, statuses } = this.props;
    
    const size = context.get('size');
    const limit = context.get('limit');
    const data = context.get('data');
    const filterEnabled = context.get('filterEnabled');
    const filters = context.get('filters');
    const remaining = context.get('remaining');

    let sortField = context.getIn(['sortFields', '0']);
    let sortDescending = false;
    if (sortField && sortField.startsWith('!')) {
      sortField = sortField.substr(1);
      sortDescending = true;
    }

    const caretIcon = (<SVG icon={sortDescending ? 'iconCaretDown' : 'iconCaretUp'} />);
    const selectEnabled = !!onSelect && !viewMode;
    const btnProps = { kind: Button.kind.grayGhost, className: css.btn, isLoading: loading };

    const displayFields = fields.filter(({ display = true }) => !!display);
    displayFields[displayFields.length - 1].extra = <SVG icon={filterEnabled ? 'iconFilterFull' : 'iconFilter'} className={css.filter} onClick={this.handleToggleFilter} />;

    return (
      <div className={classNames(css.tableContainer, className)}>
        
        <div className={classNames("ag-theme-alpine",css.grid)} style={{
          display: 'flex',flexDirection: 'column',
          height: `${size == null || remaining <= 0 ? 'calc( 100% - 10px )' : 'calc( 100% - 70px )'}`}}>
            <AgGridReact 
              suppressRowClickSelection 
              {...this.gridProps} 
              getRowId={(params) => params?.data?.id}
              columnDefs={this.agGridFields}
              rowData={data.toJS()}
              onGridReady={this.onGridReady}
            />

        </div>
        
        {size == null || remaining <= 0 ? null : (
          <div className={css.footer}>
            <div className={css.remaining}>
              <Checkbox label={`Remaining ${remaining} Selected`} checked={context.get('remainingSelected')} onClick={this.handleSelectRemainingClick} />
            </div>
            <div className={css.buttons}>
              {remaining <= limit ? null : <Button {...btnProps} onClick={this.handleLoadNext}>Load Next {limit} Results</Button>}
              <Button {...btnProps} onClick={this.handleLoadRemaining}>Load Remaining {remaining} Results</Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ContactSearchGridTable.propTypes = {
  viewMode: PropTypes.bool,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
};

ContactSearchGridTable.defaultProps = {
  viewMode: false,
};

export default connect()(ContactSearchGridTable);
