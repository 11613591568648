import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import pluralize from 'pluralize';
import { Map } from 'immutable';

import Confirm from 'app/components/Confirm';
import Prompt from 'app/components/Prompt';
import { deferExecutor } from 'app/DeferredOnLocation';
import CreateGroupPopup from 'app/components/CreateGroup';
import { contactGroupPath } from 'app/routes';
import { defaultGroupId, MAIN_CONTEXT, AVAILABLE_CONTEXT } from 'reducers';
import {
  selectContactGroups,
  selectAppendGroups,
  loadGroupContext,
  searchGroupContext,
  selectGroupContext,
  saveGroupContext,
  selectGroups,
  deleteContactGroup,
  searchContactIds,
  ContactGroupTypes,
  deleteGroupContext,
} from 'data/contacts';

import LeftPart from 'app/components/LeftPart';
import ToggleList from 'app/components/ToggleList';

import css from './style.scss';

import AppendJobEditor from './AppendJobEditor';
import ContactsList from './ContactsList';
import ContactEditor from './ContactEditor';


let i = 0;

class Contacts extends PureComponent {
  constructor(props) {
    super(props);

    this.handleAddGroup = this.handleAddGroup.bind(this);
    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleDeleteDrop = this.handleDeleteDrop.bind(this);
    this.handleGroupDrop = this.handleGroupDrop.bind(this);
    this.saveGroup = this.saveGroup.bind(this);
    this.deleteGroup = this.deleteGroup.bind(this);
    this.deleteContacts = this.deleteContacts.bind(this);
    this.getContactInfo = this.getContactInfo.bind(this);
    // this.showContactEditor = this.showContactEditor.bind(this);

    this.state = { loadId: null, dragGroup: null, dragData: null, isTableRefresh: false };
  }

  componentWillMount() {
    i = 0;
    this.loadGroup(this.props);
  }

  componentWillReceiveProps(props) {
    if (props.match.params?.groupId !== this.props.match.params?.groupId) {
      this.setState({ isTableRefresh: true }, () => this.loadGroup(props));
    }
  }

  getGroup(id) {
    return this.props.groups.find(g => g.get('id') === id);
  }

  getGroupName(id) {
    return this.getGroup(id).get('name');
  }

  getContactInfo(context = this.props.context) {
    const { searchContactIds, openAppendJobEditor } = this.props;
    const selection = context?.get('selection');

    if (selection && selection.size === context?.get('selected')) openAppendJobEditor({ contactIds: selection.toJS() });
    else searchContactIds(context, ({ response }) => openAppendJobEditor({ contactIds: response }));
  }

  loadGroup(props = this.props) {
    const { history, loadGroupContext, searchGroupContext, contactGroups, appendGroups, context, match: { params: { groupId } } , location } = props;

    // todo
    const regex = /contact\/([A-Za-z0-9]+)/;
    const matches = location.pathname.match(regex);
    let currentGroupId = matches && matches.length > 0 ? matches[1] : defaultGroupId;
    let getLatestid = currentGroupId != groupId ? currentGroupId : groupId

    const loadId = getLatestid;

    if (contactGroups.size && i++ < 1000) {
      const group = contactGroups.concat(appendGroups).find(g => g.get('id') === loadId);

      if (!group) history.push(contactGroupPath(0));
      else if (!context || context?.get('id') !== loadId) loadGroupContext(MAIN_CONTEXT, loadId, { filterEnabled: true });
      else if (this.state.loadId !== loadId && context?.get('size') == null) this.setState({ loadId }, () => searchGroupContext(context));
      else if (context?.get('size') != null) this.setState({ loadId: null });
      if (this.state.isTableRefresh) {
        let info = context.get('info').merge({ data: null });
        let cntx = context.merge({ id: loadId, info: info, index: 0 });
        this.setState({ loadId }, () => searchGroupContext(cntx));
      }
    }
  }

  handleDeleteDrop() {
    const { context } = this.props;
    const { dragGroup, dragData: { recordId, selected } } = this.state;
    const id = dragGroup.get('id');

    if (recordId) this.deleteContacts(context.merge(selected ? {} : { allSelected: false, selected: 1, selection: [recordId] }));
    else this.deleteGroup(id);
  }

  handleGroupDrop(targetId, type) {
    const { groups, confirm, context } = this.props;
    const { dragGroup, dragData: { recordId, selected } } = this.state;
    const name = targetId ? groups.find(g => g.get('id') === targetId).get('name') : null;

    let ctx;

    // Contact drag
    if (recordId) ctx = selected ? context.set('size', context?.get('selected')) : Map({ allSelected: false, selected: 1, selection: [recordId], size: 1 });

    // Group drag
    else ctx = Map({ size: dragGroup.get('size') });

    ctx = ctx.set('id', dragGroup.get('id'));

    let text = `${pluralize(`${selected ? 'selected ' : ''}contact`, ctx.get('size'), ctx.get('size') > 1)}${recordId ? '' : ` from '${dragGroup.get('name')}'`}`;
    text = targetId ? `Add the ${text} to '${name}'?` : `The ${text} will be added to this new Group.`;

    if (!targetId) {
      if (type === ContactGroupTypes.CONTACT) this.handleAddGroup(ctx, text);
      else this.getContactInfo(ctx);
    } else {
      try {
        confirm({
          caption: pluralize('Add Contact', ctx.get('size')),
          okLabel: 'Yes',
          cancelLabel: 'No',
          question: text,
          onOk: () => this.saveGroup(ctx, name),
        })();
      } catch (e) {
        console.log(e);
      }
    }
  }

  handleDragStart(dragData = {}) {
    const { groups, context } = this.props;
    this.setState({ dragGroup: groups.find(g => g.get('id') === (dragData.groupId || context?.get('id'))), dragData });
  }

  handleDragEnd() {
    this.setState({ dragGroup: null, dragData: null });
  }

  handleAddGroup(context = this.props.context, text = '') {
    // this.props.openCreateGroupPopup({ createGroup: (name, afterSuccess) => this.saveGroup(fromJS({ info: { name } }), afterSuccess) });
    this.props.prompt({
      title: 'Create Group',
      text,
      label: 'Name',
      onSubmit: (name, afterSuccess) => this.saveGroup(context, name, afterSuccess),
    });
  }

  saveGroup(context, name, afterSuccess) {
    const { saveGroupContext, history } = this.props;
    saveGroupContext((context || Map()).merge({ info: { name } }), ({ response }) => {
      if (afterSuccess) afterSuccess();
      history.push(contactGroupPath(response.find(g => g.modified).id));
    });
  }



  deleteGroup(id = this.props.context?.get('id'), afterSuccess) {
    const { confirmRemoval, deleteContactGroup } = this.props;

    const { context, history } = this.props

    if (typeof context === 'object' && context !== null && typeof context.get === 'function') {
      let newID = context?.get('id')

      confirmRemoval({
        itemType: 'Group',
        question: `Are you sure you want to delete group '${this.getGroupName(newID)}'?`,
        onOk: () => deleteContactGroup((newID), ({ response }) => {
          if (afterSuccess) afterSuccess();
          this.loadGroup(this.props)
          history.push(contactGroupPath(0))
        }),
      });
    }

  }

  deleteContacts(context1 = this.props.context, afterSuccess) {
    const { confirmRemoval, deleteGroupContext } = this.props;
    const { context, history, match: { params: { groupId } } } = this.props

    if (typeof context === 'object' && context !== null && typeof context.get === 'function') {
      const qty = context.get('selected');
      // Rest of your code
      confirmRemoval({
        itemType: 'Contact',
        question: `Are you sure you want to remove the ${pluralize('selected contact', qty, qty > 1)} from ${context?.get('id') === defaultGroupId ? 'all assigned groups' : 'this group'}?`,
        onOk: () => deleteGroupContext((context), ({ response }) => {
          if (afterSuccess) afterSuccess();
          let info = context.get('info').merge({ data: null });
          let cntx = context.merge({ id: context?.get('id'), info: info });
          this.props.searchGroupContext(cntx)
        }),
      });
    }

  }

  render() {
    const { children, contactGroups, appendGroups, openAppendJobEditor, context, location: { pathname } } = this.props;


    let bothIdsPresent = pathname.includes('contact') && pathname.split('/').length === 4;


    const { dragGroup } = this.state;
    const toggleProps = { location: '/contact', sizeProperty: 'size', selectedId: context?.get('id'), draggable: true, dragGroup, onGroupDrop: this.handleGroupDrop, onDragStart: this.handleDragStart, onDragEnd: this.handleDragEnd };
    const appendDragging = !!(dragGroup && String(dragGroup.get('id')).startsWith('A'));
    return (
      <div className={css.container}>
        <Helmet title="Contacts" />
        <div className={css.wrapper}>
          <div className={css.leftSide}>
            <LeftPart caption="My Contacts" onDeleteDrop={this.handleDeleteDrop} allowDrop={!appendDragging}>
              <ToggleList {...toggleProps} data={contactGroups} onAddClick={() => this.handleAddGroup()} type={ContactGroupTypes.CONTACT} caption="Groups" allowDrop allowAddDrop />
              <ToggleList {...toggleProps} data={appendGroups} onAddClick={openAppendJobEditor} type={ContactGroupTypes.APPEND} caption="Skip Tracing" allowAddDrop={!appendDragging} />
            </LeftPart>
          </div>
          <div className={css.rightSide}>

            {bothIdsPresent ? (

              <ContactEditor
              />
            ) : (
              <ContactsList deleteGroup={this.deleteGroup} deleteContacts={this.deleteContacts}
                getContactInfo={this.getContactInfo} onDragStart={this.handleDragStart} onDragEnd={this.handleDragEnd}
              />
            )}


          </div>
        </div>
      </div>
    );
  }
}

export { Contacts };

export default withRouter(connect(state => ({
  groups: selectGroups(state),
  contactGroups: selectContactGroups(state),
  appendGroups: selectAppendGroups(state),
  context: selectGroupContext(MAIN_CONTEXT)(state),
}), {
  saveGroupContext,
  loadGroupContext,
  searchGroupContext,
  searchContactIds,
  deleteContactGroup,
  deleteGroupContext,
  openCreateGroupPopup: CreateGroupPopup.open,
  openAppendJobEditor: AppendJobEditor.open,
  confirm: Confirm.open,
  prompt: Prompt.open,
  confirmRemoval: Confirm.confirmRemoval,
})(deferExecutor(Contacts)));
