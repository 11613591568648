import classNames from "classnames";
import React, { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap/lib";
import { useDispatch, useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import Auction from "../../../../../../assets/images/auction.svg";
import House from "../../../../../../assets/images/house-solid.svg";
import BankOwned from "../../../../../../assets/images/bank-owned.svg";
import Bankruptcy from "../../../../../../assets/images/bankruptcy.svg";
import CashBuyers from "../../../../../../assets/images/cash-buyer.svg";
import Divorce from "../../../../../../assets/images/divorce.svg";
import FailedListings from "../../../../../../assets/images/failedListings.svg";
import Flippers from "../../../../../../assets/images/flippers.svg";
import FreeClear from "../../../../../../assets/images/free-and-clear.svg";
import HighEquity from "../../../../../../assets/images/high-equity.svg";
import Liens from "../../../../../../assets/images/liens.svg";
import OnMarket from "../../../../../../assets/images/mls.svg";
import PreForeclosures from "../../../../../../assets/images/pre-foreclosures.svg";
import PreProbate from "../../../../../../assets/images/pre-probate.svg";
import SeniorOwners from "../../../../../../assets/images/senior-owners.svg";
import TaxDelinquency from "../../../../../../assets/images/tax-delinquency.svg";
import TiredLandlords from "../../../../../../assets/images/tired-landlords.svg";
import UpsideLand from "../../../../../../assets/images/upside-down.svg";
import VacantLand from "../../../../../../assets/images/vacant-land.svg";
import Vacant from "../../../../../../assets/images/vacant.svg";
import ZombieProperties from "../../../../../../assets/images/zombie-properties.svg";
import {  selectFavoriteSearchCounts, selectFavoriteSearches, selectFilterByKey, selectLeadList, selectLocalFilter, selectLocation, setFavoriteItem, selectFavoriteSelectedName, selectAvailableListingTypes } from "../../../../../../data/search";
import {
  searchLeadList,
  updateFilter,
  updateLocalFilter
} from "../../../../../../data/search/actions";
import newsearchfilter from "../../SearchFilterNew.scss";
import { LeadListScreen, leftPanelKeys } from "../../shared/ConstantsNew";
import { useFilter } from "../../TagFilterContext";

export const LeadList = () => {

  const { selectedTags, filteredFields, navIndex } = useFilter();
  const getLocalFilter = useSelector(selectLocalFilter);

  const getFavoriteSelectedName = useSelector(selectFavoriteSelectedName);
  const availableListingTypes = useSelector(selectAvailableListingTypes);
  
  const getSelectLocation = useSelector(selectLocation);

  const listingTypeOptions = LeadListScreen?.fields?.options?.filter(({ value }) => availableListingTypes.includes(value));
  
  const refs = useRef({});
 
  const getSelectFilterlistingType = useSelector(state =>
    selectFilterByKey(state, LeadListScreen?.fields?.name)
  );


  const getLeadList = useSelector(selectLeadList)

  const dispatch = useDispatch();
  const [selected, setSelected] = useState("");

  const getFavoriteSearches = useSelector(selectFavoriteSearches).toJS();
  const getFavoriteCounts = useSelector(selectFavoriteSearchCounts).toJS();

  
  const leadListsFilter = getLocalFilter.find(filter => filter.id === 'Lead Lists');

  const leadListsFilterName = leadListsFilter ? leadListsFilter.name : null;

  const filteredOptions = selectedTags.length > 0 ? LeadListScreen.fields.options.filter(option =>
    (option.tag && option.tag.some(tag => selectedTags.includes(tag))) ||
    (leadListsFilterName && option.label === leadListsFilterName) // Include the Lead Lists filter option
  ) : LeadListScreen.fields.options;



  useEffect(() => {
    if (getSelectFilterlistingType && getSelectFilterlistingType.value) {
      setSelected(getSelectFilterlistingType.value);
    } else {
      setSelected("");
    }
  }, [getSelectFilterlistingType]);

  useEffect(() => {
    if (selected) {
      const selectedItem = listingTypeOptions.find(option => (option.value === getFavoriteSelectedName?.listingType && option?.label === getFavoriteSelectedName?.name));
      if (selectedItem) {
        updateFilterLead(selectedItem);
      }
    }
  }, [selected]);

  useEffect(() => {
    const highlightedField = Object.keys(filteredFields).find(field => filteredFields[field] === navIndex);
    if (highlightedField && refs.current[highlightedField]) {
      refs.current[highlightedField].focus({ preventScroll: true });
      refs.current[highlightedField].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [filteredFields, navIndex]);

  const getSearchPanelIcons = label => {
    switch (label) {
      case "Auctions":
        return Auction;
      case "Bank Owned":
        return BankOwned;
      case "Pre-Foreclosures":
        return PreForeclosures;
      case "Bankruptcy":
        return Bankruptcy;
      case "Cash Buyers":
        return CashBuyers;
      case "Divorce":
        return Divorce;
      case "Failed Listings":
        return FailedListings;
      case "Flippers":
        return Flippers;
      case "Free & Clear":
        return FreeClear;
      case "High Equity":
        return HighEquity;
      case "Liens":
        return Liens;
      case "On Market":
        return OnMarket;
      case "Senior Owners":
        return SeniorOwners;
      case "Pre-Foreclosures":
        return PreForeclosures;
      case "Pre-Probate (Deceased Owners)":
        return PreProbate;
      case "Pre-Probate":
        return PreProbate;
      case "Tax Delinquencies":
        return TaxDelinquency;
      case "Tax Delinquency":
        return TaxDelinquency;
      case "Tired Landlords":
        return TiredLandlords;
      case "Upside Down":
        return UpsideLand;
      case "Vacant":
        return Vacant;
      case "Vacant Land":
        return VacantLand;
      case "Zombie Properties":
        return ZombieProperties;
      case "Loading":
        return "";
      default:
        //return Auction;
        return House;
    }
  };

  const updateFilterLead = item => {
    const key = LeadListScreen.fields.name;
    dispatch(updateFilter({ [key]: item.value }));
    dispatch(
      updateLocalFilter([
        {
          key: leftPanelKeys.LeadLists,
          id: "Lead Lists",
          name: item.label,
          option: item,
          apiKey: key,
          removeID: key,
          showALLDisplayValue: item.label
        }
      ])
    );

    // to update selected Favorite Active
    dispatch(setFavoriteItem(item));
    
  };


  // this flow will come when user select Favorite list it need to update here also
    useEffect(() => {
      if (getFavoriteSelectedName) {
        let updateSelectedName = listingTypeOptions.find(
          list => (list?.value === getFavoriteSelectedName?.listingType)
        );
        if (updateSelectedName) {
          updateFilterLead(updateSelectedName);
        } else {
          const favoriteSearch = Object.entries(getFavoriteSearches).find(
            ([key, value]) => (value?.listingType === getFavoriteSelectedName?.listingType)
          );
          const listingType = favoriteSearch ? favoriteSearch[1]?.listingType : undefined;
          if (listingType) {
            const selectedItem = listingTypeOptions.find(
              option => (option?.value === listingType)
            );
            if (selectedItem) {
              updateFilterLead(selectedItem);
            }
          }
        }
      }
    }, [getFavoriteSelectedName, getSelectLocation, getFavoriteSearches]);
  

  const renderLeadListItems = () => {
    return listingTypeOptions
      .sort((a, b) => a.label.localeCompare(b.label))
      .map((item, index) => (
        // <Col key={index} lg={4} md={6} xs={12} className={newsearchfilter.col}>
        <Col key={index} xl={3} lg={3} md={4} xs={12} className={newsearchfilter.col}>
          <div
            className={classNames(newsearchfilter.clsLeadList, {
              [newsearchfilter.active]: 
              (getFavoriteSelectedName?.value === item.value && 
              getFavoriteSelectedName?.label === item.label) ||
              (getFavoriteSelectedName?.listingType === item.value && 
              getFavoriteSelectedName?.name === item.label)
            })}
            onClick={() => updateFilterLead(item,)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                updateFilterLead(item);
              }
            }}
            ref={el => refs.current[item.label + '_lead'] = el}
            tabIndex={0}
          >
            <div className={newsearchfilter.clsListIcon}>
              <ReactSVG
                src={getSearchPanelIcons(item.label)}
                className={newsearchfilter.svgIcon}
              />
            </div>
            <div className={newsearchfilter.clsLeadData}>
              <p className={classNames(newsearchfilter.clsLeadLabel, {
                [newsearchfilter.clsFilteredLeadLabel]: filteredFields[item.label + '_lead'] !== undefined,
                [newsearchfilter.highlightOrange]: filteredFields[item.label + '_lead'] === navIndex
              })}>{item.label}</p>
              {/* <p className={newsearchfilter.clsLeadValue}> {getCount(item.value, index)} </p> */}
              <p className={newsearchfilter.clsLeadValue}> {getCount(item, index)} </p>
            </div>
          </div>
        </Col>
      ));
  };


  const getCount = (filter, index) => {
    const getCount = getLeadList?.find((listitem) => listitem?.searchType === filter.value);
    if (getCount) {
      return getCount?.totalCount != 0 ? getCount?.totalCount.toLocaleString() : getCountFavoriteSearches(filter).toLocaleString();
    }
    return getCountFavoriteSearches(filter).toLocaleString();
  };

  const getCountFavoriteSearches = (filter) => {
    const countEntry = Object.entries(getFavoriteSearches).find(([key, value]) => value.searchType === filter.value);
    if (countEntry) {
      const favoriteCount = getFavoriteCounts[countEntry[1].id];
      return favoriteCount ? favoriteCount : 0;
    }
    return 0;
  };

  return (
    // <div className={newsearchfilter.clsRightBodyScroll}>
    <>
      {/* {filteredOptions.length > 0 || selectedTags.length === 0 ? ( */}
        <div className={newsearchfilter.clsContentBody}>
          <div className={newsearchfilter.headerBg}>
            <h2
              className={classNames(
                newsearchfilter.clsHeading,
                newsearchfilter.m_0
              )}
            >
              Lead Lists
            </h2>
          </div>
          <Row className={classNames(newsearchfilter.row)}>
            {renderLeadListItems()}
          </Row>
        </div>
      {/* ) : null} */}
    </>
  );
};
