import classNames from 'classnames';
import Checkbox from 'components/base/Checkbox';
import FormControlWrapper from 'components/base/FormControlWraper';
import Radio from 'components/base/Radio';
import SVG from 'components/base/SVG';
import PropTypes from 'prop-types';
import React, { PureComponent, createRef } from 'react';
import isFullyVisible from 'utils/DOM/isFullyVisible';

import css from './style.scss';

let openDropdownInstance = null;

class GridCheckboxDropdown extends PureComponent {

  constructor(props) {
    super(props);

    this.ref = {};
    this.dropdownRef = createRef(); // Create a ref for the dropdown component
    this.id = `GridCheckboxDropdown-${Math.random()}`;
    this.open = false;

    this.handleRef = this.handleRef.bind(this);
    this.handleInputRef = this.handleInputRef.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onClick = this.onClick.bind(this);
    this.propsOpen = this.propsOpen.bind(this);
    this.close = this.close.bind(this);
    this.onRef = this.onRef.bind(this);

    this.state = { searchName: '', isOpen: false, direction: 'bottom', selectedValues: [], mode: 'ANY' };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);

    const { valueSep, modeSep, gridcontext, name } = this.props;

    const filters      = gridcontext.get('filters');
    const filterValue  = filters.get(name) || null;
    const splitValues  = filterValue.split(modeSep);

    const values = splitValues[1]?.split(valueSep).filter(v => v) || [];
    
    if( values.length > 0 ) this.setState({ selectedValues: values, mode: splitValues[0] });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    // Reset the openDropdownInstance if this instance is the currently open dropdown
    if (openDropdownInstance === this) {
      openDropdownInstance = null;
    }
  }

  handleClickOutside(event) {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
      this.close();
    }
  }

  componentDidUpdate() {
    if (this.state.isOpen) {
      if (this.element instanceof HTMLElement && this.state.direction === 'bottom') {
        requestAnimationFrame(() => {
          if (!isFullyVisible(this.element)) {
            //this.setState({ direction: 'top' });
          }
        });
      }
    }
    const { searchName } = this.ref;

    if (!searchName) return;

    if (!this.state.isOpen) this.open = false;
    else if (!this.open) {
      this.open = true;
      searchName.focus();
      searchName.select();
    }
  }

  getSelection() {
    const { value, valueSep, modeSep, options, gridcontext, name } = this.props;

    const { selectedValues } = this.state;
    const val = value || '';
    const modeIdx = val.indexOf(modeSep);
    const valIdx = modeIdx >= 0 ? modeIdx + 1 : 0;

    const filters = gridcontext.get('filters');
    const value1   = filters.get(name) || null;

    return {
        mode: val.substr(0, Math.max(modeIdx, 0)),
        values: selectedValues.length
          ? selectedValues.filter(v => options.some(o => String(o.value) === v))
          : [],
      };
  }

  handleRef(el) {
    if (el) this.ref[el.name] = el;
  }

  handleInputRef() {
    const { name, onInputRef, onClick } = this.props;
    if (onInputRef) onInputRef({ name, focus: onClick });
  }

  handleChange(ev) {
    const { name, options, onChange, modeSep, valueSep, modes } = this.props;
    const { name: field, value } = ev.target;



    if (field === 'searchName') this.setState({ searchName: value });
    else {
      const { selectedValues, mode } = this.state;
      const sel = this.getSelection();
      
      let newSelectedValues

      if (field.includes('-mode')) {
        // Update the mode in the state
        this.setState({ mode: value });
  
        // Call onChange with updated mode
        onChange({
          name,
          value: `${value}${modeSep}${selectedValues.join(valueSep)}`,
        });
      } else {
        // Toggle the value in the selectedValues array
        const newSelectedValues = selectedValues.includes(value)
          ? selectedValues.filter(v => v !== value)
          : [...selectedValues, value];
  
        this.setState({ selectedValues: newSelectedValues });
  
        // Call onChange with the updated selected values
        onChange({
          name,
          value: `${mode ? `${mode}${modeSep}` : ''}${newSelectedValues.join(valueSep)}`,
        });
      }
      
    }
  }

  onClick(event, ...rest) {
    event.stopPropagation(); // Prevent the event from bubbling up

    // Check if the click happened inside the dropdown
    if (this.element && this.element.contains(event.target)) {
      return;
    }

    if (this.state.isOpen) {
      this.close();
    } else {
      // Close any currently open dropdown, if it's not a parent of this one
      if (openDropdownInstance && openDropdownInstance !== this) {
        // if (!this.isChildOf(openDropdownInstance)) {
        //   openDropdownInstance.close();
        // }
      }
      this.propsOpen();
    }

    return undefined;
  }

  propsOpen() {
    if (!this.state.isOpen) {
      // Set this instance as the currently open dropdown
      openDropdownInstance = this;
      this.setState({ isOpen: true });
    }
  }

  close() {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
    }
  }

  onRef(element) {
    this.element = element;
  }
  render() {
    const { size, placeholder, options, className, dropdownClassName, filterClassName, modes, searchable, loading } = this.props;
    const { searchName, isOpen } = this.state;
    const { mode, values } = this.getSelection();
    const vals = !values.length || values.length === options.length ? [] : options.filter(o => values.includes(String(o.value)));
    const val = vals.map(o => o.label).join(' / ');
    const iconMode = !!(vals.length && vals[0].icon);

    const pinned = [];
    const unpinned = [];
    let group;
    const opts = options.filter(o => !searchable || searchName.trim() === '' || o.label.toLowerCase().includes(searchName.trim().toLowerCase()));
    opts.forEach((o) => {
      const name = o.group || '';
      if (!group || group.name !== name) {
        group = { name, options: [] };
        (o.pinned ? pinned : unpinned).push(group);
      }

      group.options.push(o);
    });

    const groups = [];
    if (pinned.length) groups.push({ name: 'pinned', options: pinned });
    if (unpinned.length) groups.push({ name: 'unpinned', options: unpinned });

    return (
      <div className={classNames(css.checkboxDropdown, className)} onClick={this.onClick} ref={this.dropdownRef}>
        <div className={classNames(css.dropdown, { [css.open]: isOpen, [css.top]: this.state.direction === 'top' }, dropdownClassName)} ref={this.onRef}>
          {!searchable ? null : (
            <div className={css.search}>
              <FormControlWrapper>
                <input type="text" value={searchName} name="searchName" onChange={this.handleChange} autoComplete="off" ref={this.handleRef} placeholder="Search..." />
              </FormControlWrapper>
            </div>
          )}
          {!modes || !modes.length ? null : (
            <div className={css.modes}>
              <div className={css.caption}>Match Mode</div>
              <div className={css.items}>
                {modes.map(m => <Radio onChange={this.handleChange} key={m.value} name={`${this.id}-mode`} label={m.label} value={m.value} checked={this.state.mode === m.value} />)}
              </div>
            </div>
          )}
          {groups.map(({ name, options }) => (
            <div className={classNames(css.groupContainer, { [css.unpinned]: name === 'unpinned' })} key={name}>
              {options.map(({ name, options }) => (
                <div className={css.group} key={name}>
                  {!name || name === '' ? null : <div className={css.groupHeader}>{name}</div>}
                  {
                    options.map(o => 
                      <Checkbox onChange={this.handleChange} 
                                key={o.value} 
                                label={o.label} 
                                value={o.value} 
                                icon={o.icon} 
                                color={o.color} 
                                checked={values.includes(String(o.value))} 
                                disabled={o.disabled || loading} />
                    )
                  }
                </div>
              ))}
            </div>
          ))}
          {opts.length ? null : <div className={css.noResults}>No Matches Found</div>}
        </div>
        <div className={classNames(css.filter, css[size], filterClassName)} title={val}>
          {iconMode ? null : <div className={`${css.value} placeholdertext`}>{!vals.length ? placeholder : val}</div>}
          {!iconMode ? null : (
            <div className={css.icons}>
              {vals.map(o => (
                <div key={o.icon}>
                  <SVG icon={o.icon} />
                  <div className={css.sep}>/</div>
                </div>
              ))}
            </div>
          )}
          <SVG icon="iconCaretDown" className={css.iconCaretDown} ref={this.handleInputRef} />
        </div>
      </div>
    );
  }
}

GridCheckboxDropdown.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  direction: PropTypes.string,
  className: PropTypes.string,
  dropdownClassName: PropTypes.string,
  filterClassName: PropTypes.string,
  searchable: PropTypes.bool,
  valueSep: PropTypes.string,
  modeSep: PropTypes.string,
  modes: PropTypes.array,
  skipCatcherCheck: PropTypes.bool,
};

GridCheckboxDropdown.defaultProps = {
  placeholder: 'All',
  direction: 'bottom',
  searchable: false,
  valueSep: ',',
  modeSep: '|',
  skipCatcherCheck: false,
};

export const AnyNoneAllModes = 'Any,None,All'.split(',').map(label => ({ label, value: label.toUpperCase() }));
export const AnyNoneModes = AnyNoneAllModes.slice(0, 2);

export default GridCheckboxDropdown;