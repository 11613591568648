import SVG from "components/base/SVG";
import React, { useState } from "react";
import { AppliedFilterSection } from "./AppliedFilterSection";
import newsearchfilter from "./SearchFilterNew.scss"; // Import the SCSS file
import { MainPanel } from "./panel/MainPanel";
import SearchRow from "./SearchRow";
import classNames from "classnames";
import { useRef } from "react";
import { useEffect } from "react";
import { ActiveSectionProvider } from "./panel/ActiveSectionContext";
import { selectLoading } from 'data/search';
import { useSelector } from "react-redux";


const SearchFilterNew = () => {

  const [isOpen, setIsOpen] = useState(false);
  const newUIFilterDropdownRef = useRef(null); // Create a ref for the dropdown container
  const loading = useSelector(selectLoading)

  useEffect(() => {
    const handleClickOutside = event => {
      // console.log("Clicked outside");
      if (
        // newUIFilterDropdownRef.current &&
        // !newUIFilterDropdownRef.current.contains(event.target) &&
        event.target.classList.contains(newsearchfilter.overlay) // Check if clicked outside the dropdown container
        // !event.target.id.includes("newuifilterRef") &&
        // !event.target.getAttribute("tabindex") && // datepicker data select
        // event.target.innerText !== "Today" && // datepicker today button
        // !event.target.classList.contains(newsearchfilter.clsAutoSearchOption) &&
        // !event.target.classList.contains(newsearchfilter.clsListClearIcon)
      ) {
        setIsOpen(false);
      }
    };
  
    console.log("Adding event listener");
    document.addEventListener("click", handleClickOutside);
    return () => {
      console.log("Removing event listener");
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);


  const toggleDropdown = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };
  
  return (
    <div ref={newUIFilterDropdownRef} className={classNames(newsearchfilter.dropdownContainer, newsearchfilter.posUnset)}>
      <div
        onClick={!loading ? toggleDropdown: null}
        className={newsearchfilter.dropdownToggleBtn}
      >
        {loading ? `Loading...`: <>Filter
        <SVG
          icon={isOpen ? "iconCaretUp" : "iconCaretDown"}
          className={newsearchfilter.dropdownCaretIcon}
        /></>}
      </div>

      {isOpen && (
        <>
        <div className={newsearchfilter.overlay}></div>
        <div className={newsearchfilter.dropdown}>
          <div className={newsearchfilter.dropdownHeader}>
            <SearchRow onClosePanel={toggleDropdown} />

            {/* <AppliedFilterSection /> */}
          </div>

          <div className={newsearchfilter.dropdownBody}>
            <ActiveSectionProvider>
              <MainPanel />
            </ActiveSectionProvider>
          </div>
          <div className={newsearchfilter.dropdownFooter}>

          </div>
        </div>
        </>
      )}
    </div>
  );
};

export default SearchFilterNew;