import React, { Component } from 'react';
import Button from '../Button/Button';
import PropTypes from 'prop-types';


class DropdownToggle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDropdown: false,
    };
    this.dropdownToggleRef = null;
    this.handleBlur = this.handleBlur.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
  }

  componentDidMount() {
    if (this.dropdownToggleRef) {
      this.dropdownToggleRef.addEventListener('blur', this.handleBlur);
    }
  }

  componentWillUnmount() {
    if (this.dropdownToggleRef) {
      this.dropdownToggleRef.removeEventListener('blur', this.handleBlur);
    }
  }

  handleBlur(e) {
    if (this.dropdownToggleRef && !this.dropdownToggleRef.contains(e.relatedTarget)) {
      this.setState({ openDropdown: false });
    }
  }

  closeDropdown() {
    this.setState({ openDropdown: false });
  }

  render() {
    return (
      <div
        onBlur={this.handleBlur}
        ref={(ref) => (this.dropdownToggleRef = ref)}
        tabIndex={0}
      >
        <Button onClick={() => this.setState({ openDropdown: !this.state.openDropdown })}>
          {this.props.buttonLabel}
        </Button>
        {this.state.openDropdown &&
          this.props.renderChildren &&
          this.props.renderChildren({ closeDropdown: this.closeDropdown })}
      </div>
    );
  }
}

DropdownToggle.propTypes = {
  buttonLabel: PropTypes.node,
  renderChildren: PropTypes.func, // Explicitly expect a function for dynamic children
};

export default DropdownToggle;
