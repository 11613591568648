import classNames from "classnames";
import Button from "components/base/Button";
import SVG from "components/base/SVG";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import newsearchfilter from "./SearchFilterNew.scss";
import SuggestedInput from 'components/SuggestedInput';

import selectSearch, {
  selectActiveContext,
  selectLocalFilter,
  selectSelectedsavesearch
} from "../../../../data/search/selectors";
import SavedSearch from "../SavedSearch";
// import FilterSearchBar from "./SearchBar";
import FilterSearchBar from "./NewSearchBar";
import { useFilter } from "./TagFilterContext";
import { useCallback } from "react";
import { clearFilter, clearLocalFilter, setFavoriteItem, setSelectedSaveSearch } from "../../../../data/search";
// import FilterSaved from "../FilterSaved"; 
import addPlus from '../../../../assets/images/add-plus.svg';
import { Label } from "react-bootstrap/lib";
import { useRef } from "react";
import { useEffect } from "react";
import NewUIFilterSaved from "./UIFilterSave";
import SaveSearchList from "./SaveSearchList";
import ShowAllButton from "./showAllButton";
import { ReactSVG } from "react-svg";
import MenuCloseArrow from '../../../../assets/images/x.svg';
import SearchIcon from '../../../../assets/images/search-icon.svg';
import {
  clearSuggestion,
  countyMap,
  fipsMap,
  searchSuggestions,
  selectSuggestion,
  setRecentSuggestions,
  setSuggestionLabel,
  setSuggestionValue,
  setSuggestions,
  selectLocation
} from 'data/search';

const MAX_RECENT_SUGGESTIONS = 15;
const RECENT_SUGGESTION_PATH = 'PropStream.RecentSuggestions.1';

const SearchRow = ({ onClosePanel }) => {
  const dispatch = useDispatch();
  const searchData = useSelector(selectSearch).toJS();
  const [searchItem, setSearchItem] = useState('');
  const [searchTimer, setSearchTimer] = useState(null);
  const location = useSelector(selectLocation);
  const getLocalFilter = useSelector(selectLocalFilter);
  useEffect(() => {
    if(location !== null){
      dispatch(setSuggestionLabel(location.toJS().label));
    }
  }, []);


  const getSelectedSaveSearchName = useSelector(selectSelectedsavesearch)

  const { clearTags } = useFilter();
  const suggestedInputRef = useRef(null);



  const resultCount = useSelector(selectActiveContext)?.get("resultCount");
  const pageCount = useSelector(selectActiveContext)?.get("pageCount");

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const dropdownSavedSearchRef = useRef(null); // Create a ref for the dropdown container
  const suggestion = useSelector(state => selectSuggestion(state)).toJS();


  const focusSuggestedInput = () => {
    if (suggestedInputRef.current) {
      suggestedInputRef.current.focus();
      suggestedInputRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  const handleClearSuggestion = () => {
    dispatch(clearSuggestion());

    setSearchItem('')

  }

  const handleFetchSuggestion = (query) => {

    dispatch(setSuggestionLabel(query));

    if (searchTimer) {
      clearTimeout(searchTimer);
      setSearchTimer(null)
    }

    if (query && query.length >= 5) {

      setSearchTimer(setTimeout(() => {



        const results = parseSuggestion(query);

        if (results.length) dispatch(setSuggestions(results));
        else dispatch(searchSuggestions(query));
      }, 800))

    }

    return Promise.resolve([]);
  }

  const handleChangeSuggestion = (item) => {

    setSearchItem(item)

    if (document.activeElement && document.activeElement.blur) document.activeElement.blur();

    const loc = item;
    if (loc) {
      const { type, cityId, id } = loc;

      if (type === 'P') loc.propertyId = id;
      else if (!cityId && type === 'C') loc.cityId = id;

      dispatch(setRecentSuggestions(setRecentSuggestion(loc)));
    }

    dispatch(setSuggestionValue(loc));
  }

  const getRecentSuggestions = () => {
    try {
      return JSON.parse(window.localStorage.getItem(RECENT_SUGGESTION_PATH)).filter(i => !!i && !!i.label);
    } catch (e) {
      return [];
    }
  };

  const setRecentSuggestion = (item) => {
    let items = [];

    try {
      items = getRecentSuggestions();

      const key = (item.label || '').trim().toLowerCase();

      if (key !== '') {
        items = items.filter(s => s.key !== key);
        items.unshift({ ...item, key });
        items.length = Math.min(items.length, MAX_RECENT_SUGGESTIONS);

        window.localStorage.setItem(RECENT_SUGGESTION_PATH, JSON.stringify(items));
      }
      // eslint-disable-next-line no-empty
    } catch (e) {
      throw e;
    }

    return items;
  };

  const parseSuggestion = (query) => {
    let localResults = [];

    /**
     * Check for county matches. Allow:
     * Orange County, CA (County Only)
     * #93974065, Orange County, CA (APN + County)
     * 06059; (FIPS Only)
     * 06059;93974065
     *
     * Use local results only if this is a FIPS and/or APN search
     */
    if (query !== '') {
      // Check for FIPS pattern
      let countyQuery = query;
      let match = /^(\d{5})#([a-z0-9\s-\s.]+)?$/i.exec(query);

      if (match && match[1]) {
        // Convert to "<apn>, <county>, <state> format
        const apn = match[2] && match[2].trim();
        const county = fipsMap[match[1]];
        if (county) countyQuery = `${apn ? `#${apn}, ` : ''}${county.search}, ${county.state}`;
      }
      // Attempt to match the pattern in the countyQuery string and return an array of matched groups.
      // If no match is found, return an empty array.
      match = /^((((apn)?#)([a-z0-9\s-\s.]+)),)?([a-z\s'-]+)(,\s*([a-z]{2}))?$/i.exec(countyQuery) || [];
      const stateMatch = match[8];
      const countyMatch = match[6] || '';
      const apn = match[5] ? match[5].trim() : null;

      if (stateMatch && countyMatch.length > 3 && apn) {
        const search = countyMatch.toLowerCase().replace(/[^a-z]+/g, ' ').replace(/\s+county$/, '').trim();
        const explicitCountySearch = countyMatch.toLowerCase().includes(' county');

        // Pull all matching counties for respective state. Ignore independent cities / non-counties unless "county" is in the search string OR APN is provided.
        // If neither of those is true then the independent city should already be in the Bing results.
        localResults = localResults.concat((countyMap[stateMatch.toUpperCase()] || []).filter(c => c.search.includes(search) && (c.county || explicitCountySearch || apn)).map((county) => {
          const label = `${apn ? `APN# ${apn}, ` : ''}${county.name}`;
          return { label, fips: county.fips, apn };
        }));
      }
    }

    return localResults;
  };

  useEffect(() => {
    const handleClickOutside = event => {
      // Close dropdown if click occurs outside of the dropdown container
      if (dropdownSavedSearchRef.current && !dropdownSavedSearchRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Add event listener to detect clicks on the document
    document.addEventListener("click", handleClickOutside);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
      clearTags();
    };
  }, []); // Run effect only once when component mounts


  const handleMenuClick = event => {
    event.stopPropagation(); // Prevent click event from propagating to document click listener
  };

  const toggleDropdownSave = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  const truncateName = name => {
    const maxLength = 18;
    if (name.length > maxLength) {
      return name.slice(0, maxLength - 3) + "..."; // Truncate and add "..."
    } else {
      return name; // Return the original name if it's shorter than or equal to 26 characters
    }
  };

  const handleOptionClick = option => {
    setSelectedOption(truncateName(option?.name));
    setIsOpen(false); // Close the dropdown when an option is selected
  };

  const closeDropdown = (event) => {
    event.stopPropagation();
    setIsOpen(false); // Close the dropdown when an option is selected
  };

  const handleViewwMore = useCallback(() => {
    onClosePanel();
    clearTags();
  }, [onClosePanel]);

  const handleOldLocalAllFilter = () => {
    dispatch(clearLocalFilter())
  };

  const handleOldClearAllFilter = () => {
    dispatch(clearFilter());
  };

  const clearSelectedSaveSearchFilter = () => {
    dispatch(setSelectedSaveSearch(null));
    dispatch(setFavoriteItem(null));

  };

  const clearAllFilter = () => {
    handleOldClearAllFilter()
    handleOldLocalAllFilter()
    clearTags()
    clearSelectedSaveSearchFilter()
  }

  return (
    <div className={newsearchfilter.clsTopHeader}>
      <div className={newsearchfilter.row}>
        <div className={newsearchfilter.col}>
          <div className={newsearchfilter.clsContentBody} ref={suggestedInputRef}>
            <div className={newsearchfilter.searchInput}>
              <div className={newsearchfilter.search}>
                <SuggestedInput
                  isRecent={suggestion?.recentLoaded}
                  value={suggestion?.label}
                  suggestions={suggestion?.items}
                  loading={suggestion?.loading}
                  onChange={handleChangeSuggestion}
                  onFetch={handleFetchSuggestion}
                  clearSearch={handleClearSuggestion}
                  placeholder="Enter County, City, Zip Codes(s) or APN #"
                />
              </div>
              <div className={`${newsearchfilter.iconSearch} ${suggestion?.items?.length && suggestion?.label?.length >= 5 && !suggestion?.loading ? '' : newsearchfilter.disabled}`}  onClick={() => this.handleChangeSuggestion(suggestion?.items[0])}>
                <img src={SearchIcon} alt="Search" />
                <span className={newsearchfilter.searchText}>Search</span>
              </div>
            </div>
          </div>
        </div>
        <div className={newsearchfilter.col}>
          <Button
            kind="link-default"
            className={newsearchfilter.clsClearFilterBtn}
            disabled={!getLocalFilter.length}
            size="large"
            onClick={() => {
              clearAllFilter()
            }}
          >
            Clear Filter
          </Button>
        </div>
        <div className={newsearchfilter.col}>
          {/* <Button kind="link-default" className={newsearchfilter.clsSaveBtn} size="large">Save</Button> */}
          {/* <FilterSaved /> */}
          <NewUIFilterSaved />
        </div>
        <div className={classNames(newsearchfilter.col, newsearchfilter.searchRowClose)}>
          <div ref={dropdownSavedSearchRef} className={newsearchfilter.dropdownContainer}>
            <div
              onClick={toggleDropdownSave}
              className={classNames(
                newsearchfilter.dropdownToggleBtn,
                newsearchfilter.dropdownSaveSerchBtn,
                { [newsearchfilter.open]: isOpen }
              )}
              tabIndex={0}
            >
              {getSelectedSaveSearchName ? truncateName(getSelectedSaveSearchName) : "Saved Searches"}
              <SVG
                icon={isOpen ? "iconCaretUp" : "iconCaretDown"}
                className={newsearchfilter.dropdownCaretIcon}
              />
            </div>
            {isOpen && (
              <SaveSearchList closeParent={closeDropdown} handleClear={clearAllFilter} />
            )}
          </div>
        </div>
      </div>

      <div className={classNames(newsearchfilter.row, newsearchfilter.searchRowClose)}>
        <div className={newsearchfilter.col}>
          <Button
            kind="link-default"
            className={newsearchfilter.clsPropertiBtn}
            size="large"
            onClick={handleViewwMore}
          >
            {/* View {resultCount > 999999 ? "99,9999 +" : resultCount.toLocaleString()} Properties */}
            View {suggestion?.value && suggestion?.value?.type == 'P' ? 1 :  resultCount > 999999 ? "999,999+" : resultCount ? resultCount.toLocaleString() : resultCount} {`${suggestion?.value && suggestion?.value?.type == 'P' || resultCount <= 1 ? 'Property' : 'Properties'}`}
          </Button>
        </div>
        <div className={newsearchfilter.col}>
          <Button
            kind="link-default"
            name="clear"
            className={classNames(newsearchfilter.clsListClearBtn, newsearchfilter.clsCloseBtnPadding)}
            onClick={handleViewwMore}
          >
            <ReactSVG src={MenuCloseArrow} className={newsearchfilter.clsListClearIcon} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SearchRow;
