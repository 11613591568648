import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import classNames from 'classnames';
import Button, { SolidButton } from 'components/Button';
import Confirm from 'app/components/Confirm';
import Accordion from 'components/base/Accordion';

import { selectAvailableListingTypes } from 'data/search';
import { PropertyTypeOptions, selectLoading } from 'data/property';
import getFields, { FilterSectionMap, FilterFieldMap, ListingTypeOptions, getField, writeFields, getDisplayFields } from './Constants';
import FieldSet from './FieldSet';
import CheckboxField from './CheckboxField';
import BooleanRadioField from './BooleanRadioField';
import css from './style.scss';
import moment from 'moment';
import { FFScoreOptionsMin, FFScoreOptionsMax, PSScoreOptions, PSScoreOptionsMin, PSScoreOptionsMax, PropertyOwnedOptions, YearBuiltOptions, LotSizeOptions, BuildingSizeOptions, NumberStoryOptions, foreclosureFactorOptions } from '../../Search/Header/searchFilterNew/shared/ConstantsNew';

const isNum = n => n != null && n !== undefined && (typeof n === 'number' || (typeof n === 'string' && n.trim() !== '' && !isNaN(+n)));

const hasSaleDate = search => !!(search.get('ownershipLengthMin') || search.get('ownershipLengthMax') || search.get('saleDateMin') || search.get('saleDateMax'));
const hasEstimatedEquity = search => isNum(search.get('estimatedEquityMin')) || isNum(search.get('estimatedEquityMax')) || isNum(search.get('estimatedEquityPercentMin')) || isNum(search.get('estimatedEquityPercentMax'));
const hasLoanToValueRatio = search => isNum(search.get('loanToValueRatioMin')) || isNum(search.get('loanToValueRatioMax'));

const getSearchFields = search => getFields(search.merge({ missingSaleIncluded: hasSaleDate(search) ? search.get('missingSaleIncluded') : null }));

  // Define the order of severity order for property condition
  const severityOrderPCMin = [
    "5.6",   // 0
    "4.6",        // 1
    "3.6",     // 2
    "2.6",        // 3
    "1.6",   // 4
    "1"       // 5
  ];
  const severityOrderPCMax = [
    "6",   // 0
    "5.5",        // 1
    "4.5",     // 2
    "3.5",        // 3
    "2.5",   // 4
    "1.5"       // 5
  ];

  // Define the order of severity order for foreclosure factor
  const severityOrderFCMin = [
    "1",   // 0
    "11",        // 1
    "31",     // 2
    "51",        // 3
    "71",   // 4
    "91"       // 5
  ];

  const severityOrderFCMax = [
    "10",   // 0
    "30",        // 1
    "50",     // 2
    "70",        // 3
    "90",   // 4
    "100"       // 5
  ];

let runaway = 0;

class SearchOptionsDropdown extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSectionChange = this.handleSectionChange.bind(this);
    this.handleInputRef = this.handleInputRef.bind(this);
    this.handleFieldClick = this.handleFieldClick.bind(this);
    this.handleFieldClear = this.handleFieldClear.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.getFilterOptions = this.getFilterOptions.bind(this);

    const { search } = props;

    this.listingTypeOptions = ListingTypeOptions.filter(({ value }) => props.availableListingTypes.includes(value));
    if (this.listingTypeOptions.length > 1) this.listingTypeOptions.unshift({ label: '', value: '' });

    this.state = {
      activeSection: '',
      ...getSearchFields(props.search),
      search,
      changes: {},
      ...this.getFilterOptions()
    };

    this.timer = null;
    this.fields = {};
    this.missingSaleManual = hasSaleDate(search);
  }

  getFilterOptions(){
    return {
      maxOptionsForFilters: {
        propCondition: PSScoreOptionsMax,
        bathCondition: PSScoreOptionsMax,
        kitchenCondition: PSScoreOptionsMax,
        intCondition: PSScoreOptionsMax,
        extCondition: PSScoreOptionsMax,
        foreclosureFactor: FFScoreOptionsMax,
      },
      minOptionsForFilters: {
        propCondition: PSScoreOptionsMin,
        bathCondition: PSScoreOptionsMin,
        kitchenCondition: PSScoreOptionsMin,
        intCondition: PSScoreOptionsMin,
        extCondition: PSScoreOptionsMin,
        foreclosureFactor: FFScoreOptionsMin,
      }
    };
  }

  componentWillReceiveProps(props) {
    const { search } = props;
    this.setState({ search, changes: {}, ...getSearchFields(search) }, () => {
      if (!this.missingSaleManual) {
        const { search, onChange } = this.props;

        if (runaway++ > 5) return;

        const missingSaleIncluded = (!(search.get('saleDateMin') || search.get('ownershipLengthMax')) && !!(search.get('ownershipLengthMin') || search.get('saleDateMax'))) || null;

        if (search.get('missingSaleIncluded', null) !== missingSaleIncluded) onChange({ missingSaleIncluded });
      }
    });
  }

  updateDisabledMaxOptions(minValue, filterKey){
    
    const minIndex = severityOrderPCMin.indexOf(minValue);

    const disabledOptions = PSScoreOptionsMax.filter(option => {
      const optionIndex = severityOrderPCMax.indexOf((option.value).toString());
      return optionIndex < minIndex;
    });

    this.setState(prevState => ({
      maxOptionsForFilters: {
        ...prevState.maxOptionsForFilters,
        [filterKey]: PSScoreOptionsMax.filter(option =>
          !disabledOptions.some(disabledOption => disabledOption.value === option.value)
        ),
      },
    }));
  };

  updateDisabledMinOptions (maxValue, filterKey) {
    
    const maxIndex = severityOrderPCMax.indexOf(maxValue);

    const disabledOptions = PSScoreOptionsMin.filter(option => {
      const optionIndex = severityOrderPCMin.indexOf((option.value).toString());
      return optionIndex > maxIndex;
    });

    this.setState(prevState => ({
      minOptionsForFilters: {
        ...prevState.minOptionsForFilters,
        [filterKey]: PSScoreOptionsMin.filter(option =>
          !disabledOptions.some(disabledOption => disabledOption.value === option.value)
        ),
      },
    }));
  };

  comboBoxValueChange (minValue, maxValue, key) {

    if (minValue !== null && minValue !== '') {
      this.updateDisabledMaxOptions(minValue, key);
    }

    if (maxValue !== null && maxValue !== '') {
      this.updateDisabledMinOptions(maxValue, key);
    }

  }

  updateDisabledPCMaxOptions(minValue, filterKey){
    
    const minIndex = severityOrderFCMin.indexOf(minValue);

    const disabledOptions = FFScoreOptionsMax.filter(option => {
      const optionIndex = severityOrderFCMax.indexOf((option.value).toString());
      return optionIndex < minIndex;
    });

    this.setState(prevState => ({
      maxOptionsForFilters: {
        ...prevState.maxOptionsForFilters,
        [filterKey]: FFScoreOptionsMax.filter(option =>
          !disabledOptions.some(disabledOption => disabledOption.value === option.value)
        ),
      },
    }));
  };

  updateDisabledPCMinOptions (maxValue, filterKey) {
    
    const maxIndex = severityOrderFCMax.indexOf(maxValue);

    const disabledOptions = FFScoreOptionsMin.filter(option => {
      const optionIndex = severityOrderFCMin.indexOf((option.value).toString());
      return optionIndex > maxIndex;
    });

    this.setState(prevState => ({
      minOptionsForFilters: {
        ...prevState.minOptionsForFilters,
        [filterKey]: FFScoreOptionsMin.filter(option =>
          !disabledOptions.some(disabledOption => disabledOption.value === option.value)
        ),
      },
    }));
  };

  comboBoxPCValueChange (minValue, maxValue, key) {

    if (minValue !== null && minValue !== '') {
      this.updateDisabledPCMaxOptions(minValue, key);
    }

    if (maxValue !== null && maxValue !== '') {
      this.updateDisabledPCMinOptions(maxValue, key);
    }

  }

  handleFieldClick({ section: { title }, name, range }, max = false) {
    this.setState({ activeSection: title || this.state.activeSection }, () => {
      const f = this.fields[range ? `${name}${max ? 'Max' : 'Min'}` : name];
      if (f) {
        if (f.focus) f.focus();
        if (f.select) f.select();
      }
    });
  }

  handleFieldClear({ name, range }) {
    const { onChange } = this.props;

    const changes = { [`${name}${range ? 'Min' : ''}`]: '' };
    if (range) changes[`${name}Max`] = '';
    if (name === 'estimatedValueGrowth') changes.estimatedValueGrowthPeriod = 'ONE_MONTH';

    onChange(changes);
  }

  handleInputRef(el) {
    if (el && el.name) this.fields[el.name] = el;
  }

  handleSectionChange(caption) {
    this.setState({ activeSection: caption === this.state.activeSection ? '' : caption });
  }

  handleChange(event) {
    const target = event.target || event;
    const { name, checked, type: elType } = target;
    let { value } = target;
    const { type, options, negativeAllowed } = getField(name);
    const { onChange, summaryMode } = this.props;
    const { search, changes } = this.state;

    if (typeof value === 'string' && !['text', 'textarea', 'list'].includes(type)) value = target.value.trim().toLowerCase();

    if (type === 'number' || type === 'currency' || type === 'percent') {
      value = String(value).trim();

      // Keep track of leading dash
      const neg = value.startsWith('-') && negativeAllowed;

      // Strip all non-digits / decimals, add leading dash back in
      value = `${neg ? '-' : ''}${value.replace(/[^\d.]/g, '')}`;

      // Ensure only one decimal present
      const idx = value.indexOf('.');
      if (idx >= 0) value = `${value.substr(0, idx)}.${value.substr(idx + 1).replace(/[\D]/g, '')}`;

      // Prepend zero if leading decimal
      if (value.startsWith('.')) value = `0${value}`;

      // Nullify if value isn't a pure dash, is not a number, or is a negative number when negative is not allowed.
      if (value !== '-' && (value === '' || isNaN(value) || (value < 0 && !negativeAllowed))) value = null;

      // Convert whole percent into decimal. Add extra zero at the end if there's a trailing decimal, as an indicator for UI to retain trailing decimal.
      if (value != null && type === 'percent' && !isNaN(value)) value = `${Number(value) / 100}${value.endsWith('.') ? '0' : ''}`;
    } else if (type === 'boolean') {
      if (elType === 'checkbox') value = checked || null;
      else if (value === '') value = null;
      else value = value === 'true';
    }else if (type === 'date') {
      if (elType === 'checkbox') value = checked || null;
      else if (type === 'date') value = value ? moment(value) : null;
      else if (value === '') value = null;
      else value = value === 'true';
    }

    if (name === 'missingSaleIncluded') this.missingSaleManual = true;

    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    const newChanges = { ...changes, [name]: value };
    const newSearch = search.merge(newChanges);
    if (summaryMode || options || !['text', 'textarea', 'number', 'currency', 'percent'].includes(type)) onChange(newChanges);
    else {
      this.setState({ search: newSearch, changes: newChanges, ...getSearchFields(newSearch) }, () => {
        this.timer = setTimeout(() => {
          this.timer = null;
          onChange(newChanges);
        }, 1000);
      });
    }
  }

  handleReset() {
    this.props.onReset();
    this.missingSaleManual = false;
    this.setState({
      activeSection: '',
      ...this.getFilterOptions()
    });
  }

  handleSave() {
    const { onSave, summaryMode, alert } = this.props;

    if (summaryMode && !getDisplayFields(this.state.fields).length) alert('Please apply at least one filter.');
    else onSave();
  }

  render() {
    const { onClose, onSave, onDelete, loading, summaryMode, location } = this.props;
    const { activeSection, fields, sectionCounts, search } = this.state;

    const sectionProps = { onChange: this.handleSectionChange, activeSection };
    const fieldProps = { search, onChange: this.handleChange, onBlur: this.handleBlur, onInputRef: this.handleInputRef, fieldMap: FilterFieldMap };
    const includeExcludeProps = { ...fieldProps, className: css.includeExclude };
    const btnProps = { loading };
    // const locationSelected = !!(search.get('shapeDefinition') || search.get('formattedAddress'));
    const propertyClassCode = search.get('propertyClassCode') || '';
    const getSectionProps = (key) => {
      const { title } = FilterSectionMap[key];
      return { ...sectionProps, caption: title, captionClass: sectionCounts[key] ? css.boldCaption : null };
    };

    return (
      <div className={css.editor}>
        <div className={css.body}>
          <div className={css.criteria}>
            {!summaryMode ? null : (
              <div className={css.summarySection}>
                <div className={css.sectionHeader}>Custom Summary</div>
                <div>
                  <FieldSet {...fieldProps} name="name" maxLength={15} />
                </div>
                <FieldSet {...fieldProps} name="description" />
                <div>
                  <CheckboxField {...fieldProps} name="defaultFilter" />
                </div>
              </div>
            )}
            {!summaryMode ? null : <div className={css.sectionHeader}>Custom Summary Filters</div>}
            <div className={css.listingType}>
              <div>
                <BooleanRadioField {...fieldProps} name="ownerOccupied" section />
                <FieldSet {...fieldProps} name="listingType" containerClass={css.section} options={this.listingTypeOptions} />
              </div>
              <div>
                <BooleanRadioField {...fieldProps} name="vacant" section />
              </div>
            </div>
            <Accordion {...getSectionProps('PROPERTY')}>
              <FieldSet {...fieldProps} name="propertyClassCode" placeholder="All Classifications" />
              {/* testing */}
              <FieldSet {...fieldProps} name="landUseCode" placeholder="All Types" direction="bottom" searchable dropdownClassName={css.propertyTypeDropdown} options={PropertyTypeOptions.filter(o => propertyClassCode === '' || !o.classCode || propertyClassCode.includes(o.classCode))} />
              <FieldSet {...fieldProps} name="bedrooms" listType="dropdown" options={PropertyOwnedOptions} />
              <FieldSet {...fieldProps} name="bathrooms" listType="dropdown" options={PropertyOwnedOptions} />
              <FieldSet {...fieldProps} name="squareFeet" listType="dropdown" options={BuildingSizeOptions} />
              <FieldSet {...fieldProps} name="lotSquareFeet" listType="dropdown" options={LotSizeOptions} />
              <FieldSet {...fieldProps} name="yearBuilt" listType="dropdown" options={YearBuiltOptions} />
              <FieldSet {...fieldProps} name="propertyFeatures" />
              <FieldSet {...fieldProps} name="units" listType="dropdown" options={PropertyOwnedOptions} />
              <FieldSet {...fieldProps} name="stories" listType="dropdown" options={NumberStoryOptions} />
              <FieldSet {...fieldProps} name="schoolDistrict" />
              <BooleanRadioField {...includeExcludeProps} name="hoaPresent" />
            </Accordion>
            <Accordion {...getSectionProps('INTELLIGENCE')}>
              <div>
                <FieldSet {...fieldProps} name="propCondition" listType="dropdown" optionsMin={this.state.minOptionsForFilters.propCondition} optionsMax={this.state.maxOptionsForFilters.propCondition} onSelectValue={(minValue, maxValue) =>
                  this.comboBoxValueChange(
                    minValue,
                    maxValue,
                    "propCondition"
                  )
                } />
                <FieldSet {...fieldProps} name="bathCondition" listType="dropdown" optionsMin={this.state.minOptionsForFilters.bathCondition} optionsMax={this.state.maxOptionsForFilters.bathCondition} onSelectValue={(minValue, maxValue) =>
                  this.comboBoxValueChange(
                    minValue,
                    maxValue,
                    "bathCondition"
                  )
                } />
                <FieldSet {...fieldProps} name="kitchenCondition" listType="dropdown" optionsMin={this.state.minOptionsForFilters.kitchenCondition} optionsMax={this.state.maxOptionsForFilters.kitchenCondition} onSelectValue={(minValue, maxValue) =>
                  this.comboBoxValueChange(
                    minValue,
                    maxValue,
                    "kitchenCondition"
                  )
                } />
                <FieldSet {...fieldProps} name="intCondition" listType="dropdown" optionsMin={this.state.minOptionsForFilters.intCondition} optionsMax={this.state.maxOptionsForFilters.intCondition} onSelectValue={(minValue, maxValue) =>
                  this.comboBoxValueChange(
                    minValue,
                    maxValue,
                    "intCondition"
                  )
                } />
                <FieldSet {...fieldProps} name="extCondition" listType="dropdown" optionsMin={this.state.minOptionsForFilters.extCondition} optionsMax={this.state.maxOptionsForFilters.extCondition} onSelectValue={(minValue, maxValue) =>
                  this.comboBoxValueChange(
                    minValue,
                    maxValue,
                    "extCondition"
                  )
                } />
                <FieldSet {...fieldProps} name="foreclosureFactor" listType="dropdown" optionsMin={this.state.minOptionsForFilters.foreclosureFactor} optionsMax={this.state.maxOptionsForFilters.foreclosureFactor} onSelectValue={(minValue, maxValue) =>
                  this.comboBoxPCValueChange(
                    minValue,
                    maxValue,
                    "foreclosureFactor"
                  )
                } />
              </div>
            </Accordion>
            <Accordion {...getSectionProps('MLS')}>
              <div>
                <BooleanRadioField {...fieldProps} name="forSale" />
                {/* Using includeExcludeProps here for the CSS since they're the same. Still gets the proper options */}
                <BooleanRadioField {...includeExcludeProps} name="rental" />
                <FieldSet {...fieldProps} name="mlsListingStatus" />
                <FieldSet {...fieldProps} name="mlsListingDate" label="MLS Status Date" />
                <FieldSet {...fieldProps} name="daysOnMarket" />
                <FieldSet {...fieldProps} name="mlsListingAmount" label="Listing Amount" />
                <BooleanRadioField {...fieldProps} name="belowMarketPrice" />
                <FieldSet {...fieldProps} name="mlsListingKeyword" label="Keyword(s) Search" />
                {/* <FieldSet {...fieldProps} name="mlsFailDate" label="Failed Listing Date" />*/}
                {/* <BooleanRadioField {...fieldProps} name="rental" label="Rental" />*/}
              </div>
            </Accordion>
            <Accordion {...getSectionProps('FORECLOSURE')}>
              <FieldSet {...fieldProps} name="foreclosureStatus2" label="Status" dropdownClassName={css.foreclosureStatusDropdown} />
              <FieldSet {...fieldProps} name="foreclosureRecordingDate" label="Recording Date" />
              <FieldSet {...fieldProps} name="auctionDate" />
              <FieldSet {...fieldProps} name="openingBidAmount" />
              <FieldSet {...fieldProps} name="foreclosureReleaseDate" />
              <FieldSet {...fieldProps} name="defaultAmount" />
              {/* <FieldSet {...fieldProps} name="documentTypeCode" />*/}
            </Accordion>
            <Accordion {...getSectionProps('OWNERSHIP')}>
              <FieldSet {...fieldProps} name="ownershipLength" />
              <FieldSet {...fieldProps} name="ownerType" />
              <FieldSet {...fieldProps} name="saleDate" />
              <FieldSet {...fieldProps} name="saleAmount" />
              <FieldSet {...fieldProps} name="propertiesOwned" />
              <FieldSet {...fieldProps} name="exemptions" />
              <FieldSet {...fieldProps} name="ownerLocation" />
              {/* {process.env.NODE_ENV === 'production' ? null : <FieldSet {...fieldProps} name="ownerDeceasedCode" />}*/}
              <BooleanRadioField {...includeExcludeProps} name="ownerDeceased" />
              <BooleanRadioField {...includeExcludeProps} name="interFamilyTransfer" />
              {!hasSaleDate(search) ? null : <CheckboxField {...fieldProps} name="missingSaleIncluded" />}
            </Accordion>
            <Accordion {...getSectionProps('LIEN')}>
              <FieldSet {...fieldProps} name="lienStatus" />
              <FieldSet {...fieldProps} name="lienTypeCode" />
              <FieldSet {...fieldProps} name="lienStatusExclude" />
              <FieldSet {...fieldProps} name="lienRecordingDate" />
              <FieldSet {...fieldProps} name="bankruptcyRecordingDate" />
              <FieldSet {...fieldProps} name="lienAmount" />
              <FieldSet {...fieldProps} name="divorceRecordingDate" />
              <FieldSet {...fieldProps} name="delinquentTaxYear" />
            </Accordion>
            <Accordion {...getSectionProps('VALUE')}>
              <FieldSet {...fieldProps} name="estimatedValue" />
              <FieldSet {...fieldProps} name="wholesaleValue" />
              <FieldSet {...fieldProps} name="estimatedRentalIncome" />
              <FieldSet {...fieldProps} name="grossYield" />
              <FieldSet {...fieldProps} name="estimatedValueGrowth" />
              <FieldSet {...fieldProps} name="estimatedValueGrowthPeriod" label="Over" />
              <FieldSet {...fieldProps} name="estimatedEquity" />
              <FieldSet {...fieldProps} name="estimatedEquityPercent" />
              <FieldSet {...fieldProps} name="loanToValueRatio" />
              <FieldSet {...fieldProps} name="assessedValue" />
              <FieldSet {...fieldProps} name="assessedImprovementValue" />
              <FieldSet {...fieldProps} name="assessedLandValue" />
              <FieldSet {...fieldProps} name="itvRatio" />
              {!hasEstimatedEquity(search) ? null : <CheckboxField {...fieldProps} name="unknownEquityIncluded" />}
              {!hasLoanToValueRatio(search) ? null : <CheckboxField {...fieldProps} name="unknownLtvIncluded" />}
            </Accordion>
            <Accordion {...getSectionProps('MORTGAGE')}>
              <FieldSet {...fieldProps} name="openMortgageQuantity" />
              <FieldSet {...fieldProps} name="openMortgageBalance" />
              <FieldSet {...fieldProps} name="mortgageLoanCode" />
              <FieldSet {...fieldProps} name="mortgageRecDate" />
              <FieldSet {...fieldProps} name="mortgageInterestRate" />
              <FieldSet {...fieldProps} name="mortgageTotalPayment" />
              {/* <BooleanRadioField {...includeExcludeProps} name="mortgageRefinance" />*/}
              {/* <FieldSet {...fieldProps} name="mortgageRefinanceDate" />*/}
              {/* <FieldSet {...fieldProps} name="mortgageLenderName" />*/}
              {/* <FieldSet {...fieldProps} name="mortgageMaturityDate" />*/}
              <FieldSet {...fieldProps} name="mortgage1LtvRatio" />
              <FieldSet {...fieldProps} name="mortgageFinancingCode" />             
              <BooleanRadioField {...includeExcludeProps} name="cashBuyer" />
              <FieldSet {...fieldProps} name="mortgage1EstimatedEquity" />           
              <BooleanRadioField {...includeExcludeProps} name="mortgageSellerCarried" />
              <BooleanRadioField {...includeExcludeProps} name="freeClear" />
            </Accordion>
            {/* {[1, 2, 3, 4].map(idx => (*/}
            {/*  <Accordion key={idx} {...getSectionProps(`MORTGAGE${idx}`)}>*/}
            {/*    <FieldSet {...fieldProps} name={`mortgage${idx}RecordingDate`} />*/}
            {/*    <FieldSet {...fieldProps} name={`mortgage${idx}Balance`} />*/}
            {/*    <FieldSet {...fieldProps} name={`mortgage${idx}LoanCode`} direction="top" />*/}
            {/*    <FieldSet {...fieldProps} name={`mortgage${idx}LenderName`} />*/}
            {/*    <FieldSet {...fieldProps} name={`mortgage${idx}InterestRate`} />*/}
            {/*    <FieldSet {...fieldProps} name={`mortgage${idx}FinancingCode`} direction="top" />*/}
            {/*    <FieldSet {...fieldProps} name={`mortgage${idx}Payment`} />*/}
            {/*    <FieldSet {...fieldProps} name={`mortgage${idx}DueDate`} />*/}
            {/*    <BooleanRadioField {...includeExcludeProps} name={`mortgage${idx}Refinance`} />*/}
            {/*    <FieldSet {...fieldProps} name={`mortgage${idx}RefinanceDate`} />*/}
            {/*    <BooleanRadioField {...includeExcludeProps} name={`mortgage${idx}Equity`} />*/}
            {/*  </Accordion>*/}
            {/* ))}*/}
          </div>
          {!location && !fields.length ? null : (
            <div className={css.summary}>
              <div className={css.summaryInner}>
                <div className={css.summaryHeader}>
                  <div className={css.summaryTitle}>My Search</div>
                  {summaryMode || location ? null : <div className={classNames(css.summaryLocation, css.noLocation)}>No Location Selected</div>}
                  {summaryMode || !location ? null : <div className={css.summaryLocation}>{location.get('label')}</div>}
                </div>
                {writeFields(fields, this.handleFieldClick, this.handleFieldClear)}
              </div>
            </div>
          )}
        </div>
        <div className={css.buttons}>
          <Button {...btnProps} onClick={onClose}>{onSave ? 'Cancel' : 'Close'}</Button>
          <Button {...btnProps} onClick={this.handleReset}>Reset</Button>
          {!onDelete ? null : <Button {...btnProps} onClick={onDelete} red>Delete Filter</Button>}
          {!onSave ? null : <SolidButton {...btnProps} onClick={this.handleSave}>{summaryMode ? 'Apply' : 'Save List'}</SolidButton>}
        </div>
      </div>
    );
  }
}

SearchOptionsDropdown.propTypes = {
  summaryMode: PropTypes.bool,
};

SearchOptionsDropdown.defaultProps = {
  summaryMode: false,
};


export default connect(state => ({
  availableListingTypes: selectAvailableListingTypes(state),
  loading: selectLoading(state),
}), {
  alert: Confirm.alert,
})(SearchOptionsDropdown);
